import React, { useEffect, useState } from 'react'
import Header from '../../components/Header/Header'
import Sidebar from '../../components/Sidebar/Sidebar'
import { useDispatch, useSelector } from 'react-redux';
import { Controller, useForm } from 'react-hook-form';
import SessionsModal from '../../components/Shared/Modals/SessionsModal';
import SessionsTable from '../../components/Table/SessionsTable/SessionsTable';
import { getAllSessions, addSession, updateSession } from '../../services/operations/sessions';
import { setSession } from '../../slices/sessionsSlice';
const AddSession = () => {

    const [sidebarOpen, setSidebarOpen] = useState(false);
    const [modalOpen, setModalOpen] = useState(false);
    const [isEdit, setIsEdit] = useState(false);

    const dispatch = useDispatch()

    const { handleSubmit, control, formState: { errors, isSubmitted }, reset } = useForm();

    const { allSessions, session } = useSelector((state) => state.sessions)

    useEffect(() => {
        dispatch(getAllSessions())
    }, [dispatch])

    useEffect(() => {
        dispatch(setSession({}))
    }, [dispatch]);

    useEffect(() => {
        reset(session)
    }, [reset, session]);

    const handleModalOpen = (e) => {
        e.stopPropagation();
        reset()
        const data = {
            meetingName: '',
            meetingDate: '',
            meetingTime: '',
            meetingLink: '',
            maxPeople: '',
            video: '',
            thumbnail: '',
        }

        dispatch(setSession(data))
        setIsEdit(false)
        setModalOpen(true);
    }

   useEffect(() => {
    if (session?.meetingDate) {
        const formattedDate = new Date(session.meetingDate).toISOString().split('T')[0];

        reset({
            ...session,
            meetingDate: formattedDate,
            video: session?.video || `<div class="w-full h-full">\n  \n</div>`,
        });
    } else {
        reset({
            ...session,
            video: session?.video || `<div class="w-full h-full">\n  \n</div>`,
        });
    }
    }, [reset, session]);

    
    const handleOnSubmit = async (data) => {

        if (isEdit) {
            const { meetingName, meetingDate, meetingTime, meetingLink, maxPeople, video, thumbnail } = data;
            dispatch(updateSession(session?.id, meetingName, meetingDate, meetingTime, meetingLink, maxPeople, video, thumbnail, setModalOpen));
        }
        else {
            const { meetingName, meetingDate, meetingTime, meetingLink, maxPeople, video, thumbnail } = data;
            dispatch(addSession(meetingName, meetingDate, meetingTime, meetingLink, maxPeople, video, thumbnail, setModalOpen));
        }
    };


    return (
        <div className="flex dark:bg-dark  h-screen overflow-hidden">
            <Sidebar sidebarOpen={sidebarOpen} setSidebarOpen={setSidebarOpen} />
            <div className="relative flex flex-col flex-1 overflow-y-auto overflow-x-hidden">
                <Header sidebarOpen={sidebarOpen} setSidebarOpen={setSidebarOpen} />
                <main>
                    <div className="px-4 sm:px-6 lg:px-8 py-8 w-full max-w-9xl mx-auto">
                        <div className='flex mt-4 mb-8 flex-row justify-between'>
                            <button onClick={(e) => handleModalOpen(e)} className='btn-brand-2'>Create Meet</button>
                        </div>
                        {allSessions && allSessions.length > 0 ? (
                            <>
                                <SessionsTable
                                    sessions={allSessions}
                                    setIsEdit={setIsEdit}
                                    setModalOpen={setModalOpen}
                                    modalOpen={modalOpen}
                                />
                            </>
                        ) : (
                            <div className='flex justify-center items-center text-4xl dark:text-zinc-300'>
                                No upcomming sessions
                            </div>
                        )}
                        <SessionsModal title={isEdit ? 'Edit Session' : 'Create Session'} setModalOpen={setModalOpen} modalOpen={modalOpen}>
                            <form onSubmit={handleSubmit(handleOnSubmit)} className='mt-6'>
                                <div className="flex flex-wrap -mx-3 mb-4">
                                    <span className='px-3 text-lg font-semibold text-light_30 mb-2 dark:text-zinc-300'>Topic</span>
                                    <div className="w-full px-3">
                                        <Controller
                                            name="meetingName"
                                            control={control}
                                            render={({ field }) => (
                                                <input
                                                    id="meetingName"
                                                    type="text"
                                                    name="meetingName"
                                                    className="form-input w-full text-gray-300"
                                                    placeholder="meetingName"
                                                    {...field}
                                                />
                                            )}
                                            rules={{
                                                required: 'meetingName is required',
                                            }}
                                        />
                                        {isSubmitted && errors.meetingName && <p className="text-red-600 text-sm italic">{errors.meetingName.message}</p>}
                                    </div>
                                </div>
                                <div className="flex flex-wrap -mx-3 mb-4">
                                    <span className='px-3 text-lg font-semibold text-light_30 mb-2 dark:text-zinc-300'>Date</span>
                                    <div className="w-full px-3">
                                        <Controller
                                            name="meetingDate"
                                            control={control}
                                            render={({ field }) => (
                                                <input
                                                    id="date"
                                                    type="date"
                                                    name="meetingDate"
                                                    className="form-input w-full text-gray-300"
                                                    placeholder="meetingDate"
                                                    {...field}
                                                />
                                            )}
                                            rules={{
                                                required: 'meetingDate is required',
                                            }}
                                        />
                                        {isSubmitted && errors.meetingDate && <p className="text-red-600 text-sm italic">{errors.meetingDate.message}</p>}
                                    </div>
                                </div>
                                <div className="flex flex-wrap -mx-3 mb-4">
                                    <span className='px-3 text-lg font-semibold text-light_30 mb-2 dark:text-zinc-300'>Meeting Time</span>
                                    <div className="w-full px-3">
                                        <Controller
                                            name="meetingTime"
                                            control={control}
                                            render={({ field }) => (
                                                <input
                                                    id="meetingTime"
                                                    type="time"
                                                    name="meetingTime"
                                                    className="form-input w-full text-gray-300"
                                                    placeholder="meetingTime"
                                                    {...field}
                                                />
                                            )}
                                            rules={{
                                                required: 'meetingTime is required',
                                            }}
                                        />
                                        {isSubmitted && errors.meetingTime && <p className="text-red-600 text-sm italic">{errors.meetingTime.message}</p>}
                                    </div>
                                </div>
                                <div className="flex flex-wrap -mx-3 mb-4">
                                    <span className='px-3 text-lg font-semibold text-light_30 mb-2 dark:text-zinc-300'>Meet Link</span>
                                    <div className="w-full px-3">
                                        <Controller
                                            name="meetingLink"
                                            control={control}
                                            render={({ field }) => (
                                                <input
                                                    id="meetingLink"
                                                    type="text"
                                                    name="meetingLink"
                                                    className="form-input w-full text-gray-300"
                                                    placeholder="meetingLink"
                                                    {...field}
                                                />
                                            )}
                                            rules={{
                                                required: 'meetingLink is required',
                                            }}
                                        />
                                        {isSubmitted && errors.meetingLink && <p className="text-red-600 text-sm italic">{errors.meetingLink.message}</p>}
                                    </div>
                                </div>
                                <div className="flex flex-wrap -mx-3 mb-4">
                                    <span className='px-3 text-lg font-semibold text-light_30 mb-2 dark:text-zinc-300'>Max People</span>
                                    <div className="w-full px-3">
                                        <Controller
                                            name="maxPeople"
                                            control={control}
                                            render={({ field }) => (
                                                <input
                                                    id="maxPeople"
                                                    type="number"
                                                    name="maxPeople"
                                                    className="form-input w-full text-gray-300"
                                                    placeholder="maxPeople"
                                                    {...field}
                                                />
                                            )}
                                            rules={{
                                                required: 'maxPeople is required',
                                            }}
                                        />
                                        {isSubmitted && errors.maxPeople && <p className="text-red-600 text-sm italic">{errors.maxPeople.message}</p>}
                                    </div>
                                </div>
                                <div className="flex flex-wrap -mx-3 mb-4">
                                    <span className='px-3 text-lg font-semibold text-light_30 mb-2 dark:text-zinc-300'>Video</span>
                                    <div className="w-full px-3">
                                        <Controller
                                            name="video"
                                            control={control}
                                            render={({ field }) => (
                                                <textarea
                                                    rows={4}
                                                    id="video"
                                                    type="text"
                                                    name="video"
                                                    className="form-input w-full text-gray-300"
                                                    placeholder="video"
                                                    {...field}
                                                />
                                            )}
                                        />
                                        {isSubmitted && errors.video && <p className="text-red-600 text-sm italic">{errors.video.message}</p>}
                                    </div>
                                </div>
                                <div className="flex flex-wrap -mx-3 mb-4">
                                    <span className='px-3 text-lg font-semibold text-light_30 mb-2 dark:text-zinc-300'>Thumbnail</span>
                                    <div className="w-full px-3">
                                        <Controller
                                            name="thumbnail"
                                            control={control}
                                            render={({ field }) => (
                                                <input
                                                    id="thumbnail"
                                                    type="text"
                                                    name="thumbnail"
                                                    className="form-input w-full text-gray-300"
                                                    placeholder="thumbnail"
                                                    {...field}
                                                />
                                            )}
                                        />
                                        {isSubmitted && errors.thumbnail && <p className="text-red-600 text-sm italic">{errors.thumbnail.message}</p>}
                                    </div>
                                </div>

                                <div className="flex flex-wrap -mx-3 mt-6">
                                    <div className="w-full px-3">
                                        <button type="submit" className="btn-brand-2 w-full">Schedule Meet</button>
                                    </div>
                                </div>
                            </form>
                        </SessionsModal>
                    </div>
                </main>
            </div>
        </div>
    )
}

export default AddSession