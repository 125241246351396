/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import Header from '../../../components/Header/Header';
import Sidebar from '../../../components/Sidebar/Sidebar';
import toast from 'react-hot-toast';
import Accordion from '../../../components/Accordion/Accordion';
import ContentEdit from './ContentEdit';
import TestEdit from './TestEdit';
import EditorialEdit from './EditorialEdit';
import NonIDEeditor from './NonIDEeditor';
import { getSingleDSAProblem } from '../../../services/operations/new-syllabus';
import { SendToBack, StepBackIcon } from 'lucide-react';
import MiscEdit from './MiscEdit';

const DSAContentEdit = () => {
    const [sidebarOpen, setSidebarOpen] = useState(false);
    const [problemNote, setProblemNote] = useState(null);
    const { CurrentProblem } = useSelector((state) => state.new_syllabus);
    const params = useParams();
    const navigate = useNavigate();
    const dispatch = useDispatch();

    const { slug } = params;

    useEffect(() => {
        if (slug) {
            dispatch(getSingleDSAProblem(slug));
        }

    }, [dispatch, slug]);

    return (
        <div className='flex  dark:bg-dark_50  h-screen overflow-hidden'>
            <Sidebar sidebarOpen={sidebarOpen} setSidebarOpen={setSidebarOpen} />
            <div className='relative flex flex-col flex-1 overflow-y-auto overflow-x-hidden'>
                <Header sidebarOpen={sidebarOpen} setSidebarOpen={setSidebarOpen} />

                <main>
                    <div className='px-4 sm:px-6 lg:px-8 py-8 w-full max-w-9xl mx-auto'>
                        <div className='mt-4 flex flex-col gap-10'>
                            {/* BACK BUTTON */}
                            <div className='flex items-center gap-4'>
                                <button
                                    onClick={() => navigate(-1)}
                                    className='flex items-center gap-2 px-4 py-2 rounded-md shadow-md'
                                >
                                    <StepBackIcon />
                                    <span>Back</span>
                                </button>
                            </div>

                            {CurrentProblem?.hasIDE ? (
                                <>
                                    <Accordion title='Content'>
                                        <ContentEdit />
                                    </Accordion>
                                    <Accordion title='Test Cases And Others'>
                                        <TestEdit />
                                    </Accordion>
                                    <Accordion title='Editorials'>
                                        <EditorialEdit />
                                    </Accordion>
                                    <Accordion title='Miscellaneous'>
                                        <MiscEdit />
                                    </Accordion>
                                </>
                            ) : (
                                <NonIDEeditor />
                            )}
                        </div>
                    </div>
                </main>
            </div>
        </div>
    );
};

export default DSAContentEdit;
