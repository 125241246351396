import { setActiveUsers, setTotalDoubts, setUniqueUsers, setCostByDay, setTotalCost, setDoubtStatsLoading, setDoubtStatsError, setDailyMetrics, setLanguageWiseSubmissions, setSubmissionStatsError, 
    setSubmissionStatsLoading, setActiveUsersSummary, setTotalUsers, setActiveUsersToday, setSummaryLoading, setSummaryError, setActiveUsersProgressSummary, setProgressSummaryLoading, setProgressSummaryError,
    setAverageFeedback, setRatingDistribution, setOneRatedFeedback, setTwoRatedFeedback, setThreeRatedFeedback, setFourRatedFeedback, setFiveRatedFeedback } from "../../slices/userStatsSlice"
import { userStatsEndpoints } from "../api"
import { apiConnector } from "../apiConnector"
import toast from 'react-hot-toast'

const { GET_DOUBT_STATS, GET_SUBMISSION_STATS, GET_ACTIVE_USERS_STATS, GET_USERS_PROGRESS_STATS, GET_AVERAGE_RATING, GET_RECENT_RATINGS, DELETE_FEEDBACK } = userStatsEndpoints;

export function getDoubtStats(start_time, end_time) {
    return async(dispatch) => {
        try {
            dispatch(setDoubtStatsLoading(true));
            const response = await apiConnector("GET", `${GET_DOUBT_STATS}?start_time=${start_time}&end_time=${end_time}`);
            if (response.data.success){
                const data = response.data.stats;
                dispatch(setActiveUsers(data.activeUsers));
                dispatch(setTotalDoubts(data.totalDoubts));
                dispatch(setUniqueUsers(data.uniqueUsers));
                dispatch(setCostByDay(data.costByDay));
                dispatch(setTotalCost(data.totalCost));
                dispatch(setDoubtStatsLoading(false));
            }
        } catch (error) {
            console.log(error);
            dispatch(setDoubtStatsError(error.response?.data?.message || "An error occurred"));
            dispatch(setDoubtStatsLoading(false));
            toast.error(error.response?.data?.message || "An error occurred");
        }
    }
}

export function getSubmissionStats(start_time, end_time) {
    return async(dispatch) => {
        try {
            dispatch(setSubmissionStatsLoading(true));
            const response = await apiConnector("GET", `${GET_SUBMISSION_STATS}?start_time=${start_time}&end_time=${end_time}`);
            if (response.data.success){
                const data = response.data.stats;
                dispatch(setDailyMetrics(data.dailyMetrics));
                dispatch(setLanguageWiseSubmissions(data.languageWiseSubmissions));
                dispatch(setSubmissionStatsLoading(false));
            }
        } catch (error) {
            console.log(error);
            dispatch(setSubmissionStatsError(error.response?.data?.message || "An error occurred"));
            dispatch(setSubmissionStatsLoading(false));
            toast.error(error.response?.data?.message || "An error occurred");
        }
    }
}

export function getActiveUsersStats(start_date, end_date) {
    return async(dispatch) => {
        try {
            dispatch(setSummaryLoading(true));
            const response = await apiConnector("GET", `${GET_ACTIVE_USERS_STATS}?start_date=${start_date}&end_date=${end_date}`);
            if (response.data.success) {
                const data = response.data.stats;
                dispatch(setActiveUsersSummary(data.activeUsers));
                dispatch(setTotalUsers(data.totalUsers));
                dispatch(setActiveUsersToday(data.activeUserCountToday));
                dispatch(setSummaryLoading(false));
            } else {
                dispatch(setSummaryError(response.data.message || "Failed to fetch summary"));
                dispatch(setSummaryLoading(false));
                toast.error(response.data.message || "Failed to fetch summary");
            }
        } catch (error) {
            console.log(error);
            dispatch(setSummaryError(error.response?.data?.message || "An error occurred"));
            dispatch(setSummaryLoading(false));
            toast.error(error.response?.data?.message || "An error occurred");
        }
    }
};

export function getActiveUsersProgressStats() {
    return async(dispatch) => {
        try {
            dispatch(setProgressSummaryLoading(true));
            const response = await apiConnector("GET", `${GET_USERS_PROGRESS_STATS}`);
            if (response.data.success) {
                const data = response.data.stats;
                dispatch(setActiveUsersProgressSummary(data));
                dispatch(setProgressSummaryLoading(false));
            } else {
                dispatch(setProgressSummaryError(response.data.message || "Failed to fetch progress summary"));
                dispatch(setProgressSummaryLoading(false));
                toast.error(response.data.message || "Failed to fetch progress summary");
            }
        } catch (error) {
            console.log(error);
            dispatch(setProgressSummaryError(error.response?.data?.message || "An error occurred"));
            dispatch(setProgressSummaryLoading(false));
            toast.error(error.response?.data?.message || "An error occurred");
        }
    }
};

export function getAverageRatings() {
    return async(dispatch) => {
        try {
            const response = await apiConnector("GET", `${GET_AVERAGE_RATING}`);
            if (response.data.success) {
                const data = response.data.stats;
                dispatch(setAverageFeedback(data.average_rating));
                dispatch(setRatingDistribution(data.rating_distribution));
            }
        } catch (error) {
            console.log(error);
            toast.error(error.response?.data?.message || "An error occurred");
        }
    }
}

export function deleteFeedback(ratingId) {
    return async() => {
        try {
            const response = await apiConnector("DELETE", `${DELETE_FEEDBACK}`, {ratingId});
            if (response.data.success) {
                toast.success(response.data.message || "User Rating Deleted");
            }
        } catch (error) {
            console.log(error);
            toast.error(error.response?.data?.message || "An error occurred");
        }
    }
}

export function getRecentRatings(rating, page) {
    return async(dispatch) => {
        try {
            const response = await apiConnector("GET", `${GET_RECENT_RATINGS}${rating}?page=${page}`);
            if (response.data.success) {
                const recentRatings = response.data.recentRatings;
                const totalRatings = response.data.total;
                const currentPage = page;

                const payload = {
                    recentRatings,
                    totalRatings,
                    currentPage
                };

                switch (rating) {
                    case 1:
                        dispatch(setOneRatedFeedback(payload));
                        break;
                    case 2:
                        dispatch(setTwoRatedFeedback(payload));
                        break;
                    case 3:
                        dispatch(setThreeRatedFeedback(payload));
                        break;
                    case 4:
                        dispatch(setFourRatedFeedback(payload));
                        break;
                    case 5:
                        dispatch(setFiveRatedFeedback(payload));
                        break;
                    default:
                        toast.error("Invalid rating");
                        break;
                }
            }
        } catch (error) {
            console.log(error);
            toast.error(error.response?.data?.message || "An error occurred");
        }
    }
}