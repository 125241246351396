import { DownloadIcon } from 'lucide-react'; // Importing X for the close button icon
import React, { useRef, useEffect } from 'react';
import { CSSTransition } from 'react-transition-group';

const TranscriptModal = ({ isOpen, onClose, transcript, downloadTranscript }) => {
  const modalContent = useRef(null);

  useEffect(() => {
    const clickHandler = (event) => {
      // Close only if clicking outside the TranscriptModal
      if (isOpen && modalContent.current && !modalContent.current.contains(event.target)) {
        onClose();
      }
    };

    document.addEventListener('mousedown', clickHandler);
    return () => document.removeEventListener('mousedown', clickHandler);
  }, [isOpen, onClose]);

  useEffect(() => {
    const keyHandler = (event) => {
      if (isOpen && event.key === 'Escape') {
        onClose();
      }
    };

    document.addEventListener('keydown', keyHandler);
    return () => document.removeEventListener('keydown', keyHandler);
  }, [isOpen, onClose]);

  useEffect(() => {
    if (isOpen) {
      document.body.style.overflow = 'hidden';
    } else {
      document.body.style.overflow = '';
    }
    return () => {
      document.body.style.overflow = '';
    };
  }, [isOpen]);

  return (
    <>
      {/* Background overlay */}
      {isOpen && (
        <div className='fixed inset-0 bg-gray-900 bg-opacity-30 z-50' aria-hidden='true' />
      )}

      {/* Modal Content */}
      <CSSTransition
        in={isOpen}
        timeout={200}
        classNames={{
          enter: 'transition ease-out duration-200 opacity-0',
          enterActive: 'opacity-100',
          exit: 'transition ease-out duration-100 opacity-100',
          exitActive: 'opacity-0',
        }}
        unmountOnExit
      >
        <div
          className='fixed inset-0 z-60 flex items-center justify-center'
          role='dialog'
          aria-modal='true'
        >
          <div
            ref={modalContent}
            className='bg-white dark:bg-dark_50 rounded-lg shadow-lg overflow-y-auto no-scrollbar w-[30%] max-h-[60%] flex flex-col'
          >
            {/* Modal Header */}
            <div className='flex items-center border-b border-gray-300 dark:border-zinc-700 px-4 py-3 relative'>
              <h2 className='font-bold text-xl text-gray-800 dark:text-zinc-100 text-center flex-grow'>
                Transcript
              </h2>
              <button
                className='text-sm font-medium text-red-500 opacity-50 hover:opacity-100 transition-colors ml-4 absolute right-4'
                onClick={onClose}
                aria-label='Close Modal'
              >
                Close
              </button>
            </div>

            {/* Modal Content */}
            <div
              className='overflow-y-auto px-4 sm:px-6 mt-4'
              style={{
                maxHeight: '400px',
                scrollbarWidth: 'thin',
                scrollbarColor: '#a0aec0 #edf2f7',
              }}
            >
              {transcript ? (
                <p className='whitespace-pre-wrap text-sm sm:text-base text-gray-800 dark:text-gray-300'>
                  {transcript}
                </p>
              ) : (
                <p className='text-gray-500 dark:text-gray-400 text-center'>
                  Transcript not available.
                </p>
              )}
            </div>

            {/* Modal Footer */}
            <div className='flex justify-end items-center border-t dark:border-zinc-700 px-4 sm:px-6 py-3'>
              {/* Download Transcript */}
              {transcript && (
                <button
                  className='text-lg sm:text-xl text-red-500 opacity-50 hover:opacity-100 transition-opacity mr-4'
                  title='Download Transcript'
                  onClick={downloadTranscript}
                >
                  <DownloadIcon />
                </button>
              )}
            </div>
          </div>
        </div>
      </CSSTransition>
    </>
  );
};

export default TranscriptModal;
