/* eslint-disable no-unused-vars */
import React, { useState, useEffect } from 'react';
import CallsTableItem from './CallTableItem';
import { useSelector } from 'react-redux';
import { useDispatch } from 'react-redux';
import { setFiltered } from '../../../slices/callsSlice';

const tableHeadingsMap = {
  dropped: [
    { key: 'no', label: 'No.' },
    { key: 'email', label: 'Email' },
    { key: 'createdAt', label: 'Created At' },
    { key: 'category', label: 'Call Status' },
    { key: 'callTime', label: 'Call Time' },
    { key: 'details', label: 'Details' },
    { key: 'codeUsed', label: 'Code Used' },
  ],
  failed: [
    { key: 'no', label: 'No.' },
    { key: 'email', label: 'Email' },
    { key: 'paymentTime', label: 'Payment Time' },
    { key: 'category', label: 'Call Status' },
    { key: 'callTime', label: 'Call Time' },
    { key: 'details', label: 'Details' },
    { key: 'codeUsed', label: 'Code Used' },
  ],
  whatsapp: [
    { key: 'no', label: 'No.' },
    { key: 'name', label: 'Name' },
    { key: 'email', label: 'Email' },
    { key: 'phoneNo', label: 'Phone Number' },
    { key: 'buying_status', label: 'Buying Status' },
    { key: 'status_update_time', label: 'Status Update Time' },
    { key: 'details', label: 'Details' },
    { key: 'codeUsed', label: 'Code Used' },
  ],
};

const CallsTable = ({ calls, isEnrolled }) => {
  const userType = useSelector((state) => state.calls.userType);
  const dispatch = useDispatch();
  const [filteredCalls, setFilteredCalls] = useState(calls);

  useEffect(() => {
    const filtered = calls.filter((data) => {
      if (isEnrolled === 'enrolled') {
        return data?.isEnrolled === 1;
      } else if (isEnrolled === 'not enrolled') {
        return data?.isEnrolled !== 1;
      }
      return true;
    });
    setFilteredCalls(filtered);

    dispatch(setFiltered(calls.length - filtered.length));

  }, [dispatch, calls, isEnrolled]);

  const tableHeadings = tableHeadingsMap[userType];

  return (
    <div className='font-primary  relative'>
      <div className=' bg-white dark:bg-dark_50  rounded-t-xl'>
        <div className='overflow-x-auto no-scrollbar'>
          <table className='table-auto w-full divide-y divide-gray-200 overflow-y-auto no-scrollbar'>
            <thead className='text-xs uppercase  text-[#8C8C8C] '>
              <tr>
                {tableHeadings.map((heading) => (
                  <th
                    key={heading.key}
                    className='px-2 border-2 border-lead100 dark:border-lead500 first:pl-5 last:pr-5 py-3 whitespace-nowrap'
                  >
                    <div className='font-semibold text-center'>{heading.label}</div>
                  </th>
                ))}
              </tr>
            </thead>
            {filteredCalls?.map((data, index) => (
              <CallsTableItem
                num={index + 1}
                key={data?.id}
                id={data?.id}
                name={data?.name}
                email={data?.email}
                status={data?.call_status}
                code_used={data?.code_used}
                call_time={data?.call_time}
                payment_time={data?.payment_time}
                phone_number={data?.phone_number}
                status_update_time={data?.status_update_time}
                created_at={data?.created_at}
                buying_status={data?.buying_status}
              />
            ))}
          </table>
        </div>
      </div>
    </div>
  );
};

export default CallsTable;
