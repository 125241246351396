import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    userType: '',
    allUserCalls: { users: [], count: 0 },
    userDetails: {},
    callDetails: { transcript: '', notes: '', duration: '' },
    email: '',
    status: '',
    code_used: '',
    loading: false,
    error: null,
    userViaEmail: {},
    call_time: '',
    filtered: 0,
    userAdded: false,
};

const callsSlice = createSlice({
    name: 'calls',
    initialState,
    reducers: {
        setUserCalls(state, action) {
            state.allUserCalls = {
                users: action.payload.users,
                count: action.payload.count,
            };
            console.log(state.allUserCalls);
        },
        setUserDetails(state, action) {
            state.userDetails = action.payload;
            console.log(state.userDetails);
        },
        setCallDetailsRedux(state, action) {
            state.callDetails = action.payload;
        },
        updateCallStatusRedux(state, action) { 
            state.status = action.payload;
        },
        updateBuyingStatusRedux(state, action) { 
            state.status = action.payload;
        },
        setEmail(state, action) {
            state.email = action.payload;
        },
        setCodeUsedRedux(state, action) {
            state.code_used = action.payload;
        },
        setLoading(state, action) {
            state.loading = action.payload;
        },
        setError(state, action) {
            state.error = action.payload;
        },
        setUserType(state, action) {
            state.userType = action.payload;
        },
        setUserViaEmail(state, action) {
            state.userViaEmail = action.payload;
        },
        setCallTimeRedux(state, action) {
            state.call_time = action.payload;
        },
        setFiltered(state, action) {
            state.filtered = action.payload;
        },
        setUserAdded(state, action) {
            state.userAdded = action.payload;
        }
    }
});

export const {
    setUserCalls,
    setUserDetails,
    updateCallStatusRedux,
    setCodeUsedRedux,
    setLoading,
    setError,
    setUserType,
    setEmail,
    setCallDetailsRedux,
    setUserViaEmail,
    setCallTimeRedux,
    setFiltered,
    setUserAdded,
    updateBuyingStatusRedux
} = callsSlice.actions;

export default callsSlice.reducer;
