import { Controller, useForm } from 'react-hook-form';
import { useSelector } from 'react-redux';
import { useEffect, useState } from 'react';
import Accordion from '../../../components/Accordion/Accordion';
import { BadgeX } from 'lucide-react';
import { updateDSAProblem } from '../../../services/operations/new-syllabus';
import { useDispatch } from 'react-redux';

const NonIDEeditor = () => {
    const { CurrentProblem } = useSelector((state) => state.new_syllabus);

    
    const {
        handleSubmit,
        reset,
        control,
        formState: { errors },
    } = useForm({
        defaultValues: {
            problem_name: CurrentProblem?.problem_name || '',
            slug: CurrentProblem?.slug || '',
            hasIDE: CurrentProblem?.hasIDE === 1,
            video_link: CurrentProblem?.video_link || '',
            editorial: CurrentProblem?.editorial || {},
            new_editorial: CurrentProblem?.new_editorial || [{name: "Solution", editorial: ""}]
        },
    });

    // Keep editorial as object entries for old format
    const [editorials, setEditorials] = useState([]);
    // Keep new_editorial as array of objects
    const [newEditorials, setNewEditorials] = useState([]);


    useEffect(() => {
        if (CurrentProblem?.editorial) {
            // Convert editorial object to array of entries for rendering
            const editorialEntries = Object.entries(CurrentProblem.editorial);
            setEditorials(editorialEntries);
        }

        if (CurrentProblem?.new_editorial) {
            setNewEditorials(CurrentProblem.new_editorial);
        }

        reset({
            problem_name: CurrentProblem?.problem_name || '',
            slug: CurrentProblem?.slug || '',
            hasIDE: CurrentProblem?.hasIDE === 1,
            video_link: CurrentProblem?.video_link || '',
            editorial: CurrentProblem?.editorial || {},
            new_editorial: CurrentProblem?.new_editorial || [{name: "Solution", editorial: ""}]
        });
    }, [CurrentProblem, reset]);

    const handleKeyChange = (index, newKey) => {
        const updatedEditorials = [...editorials];
        updatedEditorials[index][0] = newKey;
        setEditorials(updatedEditorials);
    };

    const handleValueChange = (index, newValue) => {
        const updatedEditorials = [...editorials];
        updatedEditorials[index][1] = newValue;
        setEditorials(updatedEditorials);
    };

    const handleNewEditorialValueChanges = (index, newValue, label) => {
        const updatedEditorials = newEditorials.map((editorial, idx) =>
            idx === index ? { ...editorial } : editorial
          );
              
        if (label === "editorial") {
            updatedEditorials[index].editorial = newValue;
        } else if (label === "name") {
            updatedEditorials[index].name = newValue;
        }
        setNewEditorials(updatedEditorials);
    };

    const {isProd} = useSelector((state) => state.auth);
    const dispatch = useDispatch();
    
    const onSubmit = (data) => {
        // Convert editorials back to object format for old editorial
        const editorialData = Object.fromEntries(editorials);

        const problem_data = {
            type: 'nonIDE',
            id: CurrentProblem.id,
            problem_name: data.problem_name,
            slug: CurrentProblem.slug,
            hasIDE: data.hasIDE ? 1 : 0,
            video_link: data.video_link,
            editorial: editorialData,
            new_editorial: newEditorials,
        };


        dispatch(updateDSAProblem(problem_data, isProd));
    };


    return (
        <form onSubmit={handleSubmit(onSubmit)} className='flex flex-col gap-12'>
            <div className='flex gap-5 mb-8'>
                <div className='w-1/2'>
                    <div className='flex items-center gap-x-4'>
                        <span className='text-gray-800 dark:text-zinc-300 text-[16px] font-medium'>
                            Problem Name <span className='text-red-500'>*</span>
                        </span>
                        {errors.problem_name && (
                            <span className='text-red-500'>
                                Please enter at least 10 characters.
                            </span>
                        )}
                    </div>
                    <Controller
                        name='problem_name'
                        control={control}
                        render={({ field }) => (
                            <input
                                type='text'
                                name='problem_name'
                                className='form-input w-full text-gray-300'
                                placeholder='problem_name'
                                {...field}
                            />
                        )}
                        rules={{
                            required: 'problem_name is required',
                        }}
                    />
                </div>

                <div className='w-1/2'>
                    <div className='flex items-center gap-x-4'>
                        <span className='text-gray-800 dark:text-zinc-300 text-[16px] font-medium'>
                            Slug <span className='text-red-500'>*</span>
                        </span>
                        {errors.slug && (
                            <span className='text-red-500'>
                                Please enter at least 10 characters.
                            </span>
                        )}
                    </div>
                    <Controller
                        name='slug'
                        control={control}
                        render={({ field }) => (
                            <input
                                type='text'
                                name='slug'
                                className='form-input w-full text-gray-300'
                                placeholder='slug'
                                {...field}
                            />
                        )}
                        rules={{
                            required: 'slug is required',
                        }}
                    />
                </div>
            </div>
            <div className='flex gap-5 items-center'>
                <div className='flex items-center gap-x-4'>
                    <span className='text-gray-800 dark:text-zinc-300 text-[16px] font-medium'>
                        hasIDE <span className='text-red-500'>*</span>
                    </span>
                    {errors.hasIDE && (
                        <span className='text-red-500'>Please enter at least 10 characters.</span>
                    )}
                </div>
                <Controller
                    name='hasIDE'
                    control={control}
                    render={({ field }) => (
                        <input
                        type='checkbox'
                        name='hasIDE'
                        className='h-10 w-10 dark:text-blue-600'
                        checked={field.value}
                        {...field}
                    />
                    )}
                  
                />
            </div>
            <div className=''>
                <div className='flex items-center gap-x-4 mb-2'>
                    <span className='text-gray-800 dark:text-zinc-300 text-[16px] font-medium'>
                        Video 
                    </span>
                </div>
                <Controller
                    name='video_link'
                    control={control}
                    render={({ field }) => (
                        <input
                            type='text'
                            name='video_link'
                            className='form-input w-full text-gray-300'
                            placeholder='video_link'
                            {...field}
                        />
                    )}
                />
            </div>
            
            {Array.isArray(CurrentProblem.editorial) ? 
                <OldEditorialWithArray 
                    editorials={editorials} 
                    handleKeyChange={handleKeyChange} 
                    handleValueChange={handleValueChange} 
                    setEditorials={setEditorials} 
                /> : <OldEditorials 
                    editorials={editorials} 
                    handleKeyChange={handleKeyChange} 
                    handleValueChange={handleValueChange} 
                    setEditorials={setEditorials} 
            />}


            <Accordion key='newEditorials' title='New Editorial'>
                <div className='flex flex-col gap-4'>
                    {newEditorials.length > 0 ? (
                        newEditorials.map((editorialData, index) => {

                            return (<div key={index} className='flex items-center gap-2'>
                            <div className='w-full'>
                                <Accordion key={index} title={editorialData.name}>
                                    <div className='flex items-center gap-x-4 mb-2'>
                                        <input
                                            type='text'
                                            value={editorialData.name}
                                            onChange={(e) =>
                                                handleNewEditorialValueChanges(index, e.target.value, "name")
                                            }
                                            className='form-input w-1/3 text-gray-800 border rounded p-2'
                                            placeholder='Editorial Name'
                                        />
                                    </div>
                                    <div className='flex items-center gap-x-4 mb-2'>
                                        <textarea
                                            value={editorialData.editorial}
                                            onChange={(e) =>
                                                handleNewEditorialValueChanges(index, e.target.value, "editorial")
                                            }
                                            className='form-input w-full text-gray-800 border rounded p-2 h-80'
                                            placeholder='Editorial Content'
                                        />
                                    </div>
                                </Accordion>
                            </div>
                            <BadgeX
                                onClick={() => {
                                    const updatedEditorials = newEditorials.filter((_, i) => i !== index);
                                    setNewEditorials(updatedEditorials);
                                }}
                                className='cursor-pointer text-red-500'
                            />
                        </div>)
                        })
                    ) : (
                        <div className='flex items-center justify-center text-gray-500'>
                            No editorials added yet.
                        </div>
                    )}
                    <button
                        type='button'
                        className='p-2 rounded bg-blue-500 text-white w-32'
                        onClick={() => {
                            setNewEditorials([...newEditorials, { name: "Enter name", editorial: "" }]);
                        }}
                    >
                        Add Editorial
                    </button>
                </div>
            </Accordion>

            <div className='mt-4 flex gap-10'>
                <button type='submit' className='p-2 rounded bg-blue-500 text-white w-32'>
                    Save
                </button>
                <button
                    type='button'
                    className='p-2 rounded bg-blue-500 text-white w-32'
                    onClick={() => {
                        const editorialData = newEditorials;
                        localStorage.setItem('editorialData', JSON.stringify(editorialData));
                        window.open('/manage-content/problem/new-preview', '_blank');
                    }}
                >
                    Preview
                </button>
            </div>
        </form>
    );
};


const OldEditorials = ({ editorials, handleKeyChange, handleValueChange, setEditorials }) => {
    return (
        <Accordion key="editorials" title="Old Editorials">
            <div className="flex flex-col gap-4">
                {editorials.length > 0 ? (
                    editorials.map(([key, value], index) => (
                        <div key={index} className="flex items-center gap-2">
                            <div className="w-full">
                                <Accordion key={index} title={key}>
                                    <div className="flex items-center gap-x-4 mb-2">
                                        <input
                                            type="text"
                                            value={key}
                                            onChange={(e) => handleKeyChange(index, e.target.value)}
                                            className="form-input w-1/3 text-gray-800 border rounded p-2"
                                            placeholder="Editorial Key"
                                        />
                                    </div>
                                    <div className="flex flex-col gap-4 mb-2">
                                        {typeof value === 'object' && value !== null ? (
                                            <>
                                                <div className="flex items-center gap-x-4 mb-2">
                                                    <input
                                                        type="text"
                                                        value={value.name || ''}
                                                        onChange={(e) => handleValueChange(index, {
                                                            ...value,
                                                            name: e.target.value,
                                                        })}
                                                        className="form-input w-full text-gray-800 border rounded p-2"
                                                        placeholder="Name"
                                                    />
                                                </div>
                                                <div className="flex items-center gap-x-4 mb-2">
                                                    <textarea
                                                        value={value.editorial || ''}
                                                        onChange={(e) => handleValueChange(index, {
                                                            ...value,
                                                            editorial: e.target.value,
                                                        })}
                                                        className="form-input w-full text-gray-800 border rounded p-2 h-80"
                                                        placeholder="Editorial"
                                                    />
                                                </div>
                                            </>
                                        ) : (
                                            <div className="flex items-center gap-x-4 mb-2">
                                                <textarea
                                                    value={value || ''}
                                                    onChange={(e) => handleValueChange(index, e.target.value)}
                                                    className="form-input w-full text-gray-800 border rounded p-2 h-80"
                                                    placeholder="Editorial Value"
                                                />
                                            </div>
                                        )}
                                    </div>
                                </Accordion>
                            </div>
                            <BadgeX
                                onClick={() => {
                                    const updatedEditorials = editorials.filter((_, i) => i !== index);
                                    setEditorials(updatedEditorials);
                                }}
                                className="cursor-pointer text-red-500"
                            />
                        </div>
                    ))
                ) : (
                    <div className="flex items-center justify-center text-gray-500">
                        No editorials added yet.
                    </div>
                )}
                <button
                    type="button"
                    className="p-2 rounded bg-blue-500 text-white w-32"
                    onClick={() => {
                        setEditorials([...editorials, ['Enter name', '']]);
                    }}
                >
                    Add Editorial
                </button>
            </div>
        </Accordion>
    );
};




const OldEditorialWithArray = ({editorials, handleKeyChange, handleValueChange, setEditorials}) => {
    return (            <Accordion key='editorials' title='Old Editorials'>
    <div className='flex flex-col gap-4'>
        {editorials.length > 0 ? (
            editorials.map(([key, value], index) => (
                <div key={index} className='flex items-center gap-2'>
                    <div className='w-full'>
                        <Accordion key={index} title={value?.name}>
                            <div className='flex items-center gap-x-4 mb-2'>
                                <input
                                    type='text'
                                    value={value?.name}
                                    onChange={(e) => handleKeyChange(index, e.target.value)}
                                    className='form-input w-1/3 text-gray-800 border rounded p-2'
                                    placeholder='Editorial Key'
                                />
                            </div>
                            <div className='flex items-center gap-x-4 mb-2'>
                                <textarea
                                    value={value.editorial}
                                    onChange={(e) => handleValueChange(index, e.target.value)}
                                    className='form-input w-full text-gray-800 border rounded p-2 h-80'
                                    placeholder='Editorial Value'
                                />
                            </div>
                        </Accordion>
                    </div>
                    <BadgeX
                        onClick={() => {
                            const updatedEditorials = editorials.filter((_, i) => i !== index);
                            setEditorials(updatedEditorials);
                        }}
                        className='cursor-pointer text-red-500'
                    />
                </div>
            ))
        ) : (
            <div className='flex items-center justify-center text-gray-500'>
                No editorials added yet.
            </div>
        )}
        <button
            type='button'
            className='p-2 rounded bg-blue-500 text-white w-32'
            onClick={() => {
                setEditorials([...editorials, ['Enter name', '']]);
            }}
        >
            Add Editorial
        </button>
    </div>
</Accordion>)
}




export default NonIDEeditor;
