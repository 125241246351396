import toast from 'react-hot-toast';
import { setAllSessions, setEnrolledUsers, clearEnrolledUsers } from "../../slices/sessionsSlice";
import { sessionsEndpoints } from "../api";
import { apiConnector } from "../apiConnector";
import { sendEmailToPremiumUsers } from './notifications';

const { GET_SESSIONS, ADD_SESSION, DEL_SESSION, UPDATE_SESSION, GET_SESSION } = sessionsEndpoints;

export function getAllSessions() {
    return async (dispatch) => {
        try {
            const response = await apiConnector("GET", GET_SESSIONS);
            if (response.data.success) {
                dispatch(setAllSessions(response.data.all_sessions));
            }
        } catch (error) {
            console.log(error);
            toast.error(error.response?.data?.message || "An error occurred");
        }
    }
}

export function getSession(id) {
    return async (dispatch) => {
        try {
            dispatch(clearEnrolledUsers());
            const response = await apiConnector("GET", GET_SESSION + `${id}`);
            if (response.data.success) {
                const emails = Array.isArray(response.data?.emails)
                    ? response.data?.emails.join(", ")
                    : ''; 
                dispatch(setEnrolledUsers(emails));
                return true;
            }
        } catch (error) {
            console.log(error);
            toast.error(error.response?.data?.message || "An error occurred");
        }
        return false;
    };
}

export function addSession(meetingName, meetingDate, meetingTime, meetingLink, maxPeople, video, thumbnail, setModalOpen, isProd) {
    return async (dispatch) => {
        try {
            const response = await apiConnector("POST", ADD_SESSION, { meetingName, meetingDate, meetingTime, meetingLink, maxPeople, video, thumbnail })
            if (response.data.success) {
                toast.success(response.data.message);
                const sendEmailTitle = `Session Update: ${meetingName}`;
                const sendEmailMessage = getSessionEmailMessage(meetingName, meetingDate, meetingTime);
                dispatch(sendEmailToPremiumUsers(sendEmailTitle, sendEmailMessage));
                dispatch(getAllSessions());
                if (setModalOpen) {
                    setModalOpen(false);
                }
            } else {
                toast.error(response.data.message || "Failed to add session");
            }
        } catch (error) {
            console.log(error)
            toast.error(error.response?.data?.message || "An error occurred");
        }
    }
}

export function updateSession(id, meetingName, meetingDate, meetingTime, meetingLink, maxPeople, video, thumbnail, setModalOpen, isProd) {
    return async (dispatch) => {
        try {
            const response = await apiConnector("PUT", UPDATE_SESSION + `${id}`, { meetingName, meetingDate, meetingTime, meetingLink, maxPeople, video, thumbnail });
            if (response.data.success) {
                toast.success(response.data.message);
                dispatch(getAllSessions());
                if (setModalOpen) {
                    setModalOpen(false);
                }
            } else {
                toast.error(response.data.message || "Failed to update session");
            }
        } catch (error) {
            console.log(error);
            toast.error(error.response?.data?.message || "An error occurred");
        }
    }
}

export function deleteSession(id, setModalDeleteOpen, isProd) {
    return async (dispatch) => {
        try {
            const response = await apiConnector("DELETE", DEL_SESSION + `${id}`);
            if (response.data.success) {
                dispatch(getAllSessions());
                toast.success(response.data.message);
                setModalDeleteOpen(false);
            } else {
                toast.error(response.data.message || "Failed to delete session");
            }
        } catch (error) {
            console.log(error);
            toast.error(error.response?.data?.message || "An error occurred");
        }
    }
}

function getSessionEmailMessage(sessionName, meetingDate, meetingTime) {
    const istDateTime = new Date(`${meetingDate}T${meetingTime}:00+05:30`);

    const utcDateTime = istDateTime.toISOString().replace('T', ' ').slice(0, 16); // Format as "YYYY-MM-DD HH:MM"

    return `
        <div style="font-family: Arial, sans-serif; line-height: 1.6;">
            <h4 style="color: #333;">Hey,</h4>
            <p>Hope you're doing well!</p>
            <p>I(Striver) will be taking a session this weekend, and you can find the details below.</p>
            <table style="border-collapse: collapse; width: 100%; max-width: 600px; margin: 20px 0;">
                <tr>
                    <td style="padding: 8px; border: 1px solid #ddd; font-weight: bold;">Session Name:</td>
                    <td style="padding: 8px; border: 1px solid #ddd;">${sessionName}</td>
                </tr>
                <tr>
                    <td style="padding: 8px; border: 1px solid #ddd; font-weight: bold;">Date and Time (IST):</td>
                    <td style="padding: 8px; border: 1px solid #ddd;">
                        ${meetingDate}, ${convertTo12HourFormat(meetingTime)} IST
                    </td>
                </tr>
                <tr>
                    <td style="padding: 8px; border: 1px solid #ddd; font-weight: bold;">Date and Time (UTC):</td>
                    <td style="padding: 8px; border: 1px solid #ddd;">
                        ${utcDateTime} UTC
                    </td>
                </tr>
            </table>
            <p>Please note that the session timings are based on Indian Standard Time (IST). For your convenience, the equivalent UTC time is also mentioned.</p>
            <p>Enroll right away by visiting here: https://takeuforward.org/plus/weekly-sessions</p>
            <p>Let's catch-up soon!</p>
            <p>Best regards,</p>
            <p><strong>Striver</strong></p>
        </div>
    `;
}

function convertTo12HourFormat(time24) {
    const [hour, minute] = time24.split(':');
    const period = +hour < 12 ? 'AM' : 'PM';
    const hour12 = (+hour % 12) || 12; // Convert 0 → 12 for midnight
    return `${hour12}:${minute} ${period}`;
}
