import React, { useState } from 'react'
import { setConfig } from '../../../slices/configSlice'
import { useDispatch } from 'react-redux'
import ConfigsModal from '../../Shared/Modals/ConfigsModal'
import { deleteConfig } from '../../../services/operations/config'

const ConfigsItem = (props) => {
    const { setModalOpen, setIsEdit } = props

    const dispatch = useDispatch()
    const [modalDeleteOpen, setModalDeleteOpen] = useState(false);

    const handleConfigEdit = (e) => {
        e.stopPropagation()
        const data = {
            id: props.id,
            key: props.ConfigKey,
            value: props.value,
        }
        dispatch(setConfig(data))
        setIsEdit(true)
        setModalOpen(true)
    }
    const handleConfigDeleteModal = (e) => {
        e.stopPropagation()

        setModalDeleteOpen(true);
    }
    
    const handleConfigDelete = (e) => {
        const isProd = props.isProd;
        dispatch(deleteConfig(props?.id, setModalDeleteOpen, isProd));
    }


    return (
        <tbody className="text-[13px]">
            <tr className='border-t-2 border-2 border-lead100 dark:border-lead500'>
                <td className="px-2 border-l-2 border-lead100 dark:border-lead500 first:pl-5 last:pr-5 py-4 whitespace-nowrap">
                    <div className="font-medium cursor-pointer text-gray-800 dark:text-zinc-200 flex justify-center text-center max-w-[200px]">
                        {props?.ConfigKey}
                    </div>
                </td>
                <td className="px-2 border-l-2 border-lead100 dark:border-lead500 first:pl-5 last:pr-5 py-4 whitespace-nowrap">
                    <div className="font-medium cursor-pointer text-gray-800 dark:text-zinc-200 flex justify-center text-center overflow-x-scroll max-w-[200px]">
                        {props?.value}
                    </div>
                </td>
                <td className="px-2 border-l-2 border-lead100 dark:border-lead500 first:pl-5 last:pr-5 py-4 whitespace-nowrap">
                    <div className="flex justify-center items-center text-zinc-700 dark:text-zinc-300 gap-x-4">
                        <button onClick={handleConfigEdit} className='btn bg-green-500 text-white  border-green-500 transition-all duration-300 ease-in-out '>Edit</button>
                        <button onClick={handleConfigDeleteModal} className='btn bg-red-500 border-red-500 text-white transition-all duration-300 ease-in-out'>Delete</button>
                    </div>
                </td>
            </tr>
            <ConfigsModal title={"Delete Config"} modalOpen={modalDeleteOpen} setModalOpen={setModalDeleteOpen}>
                <div className='flex flex-col gap-y-4 items-center justify-center'>
                    <span className='text-4xl'>Are You Sure?</span>
                    <button className='btn-brand-2 mt-4' onClick={() => handleConfigDelete()}>Yes, Delete</button>
                </div>
            </ConfigsModal>
        </tbody >
        
    )
}

export default ConfigsItem