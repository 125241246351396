import { useDispatch } from 'react-redux';
import { addCallDetails } from '../services/operations/calls'; // Update the path as needed
import toast from 'react-hot-toast';

const useUpdateCallDetails = () => {
  const dispatch = useDispatch();

  const updateCallDetails = ({
    transcript,
    duration,
    notes,
    email,
    userType,
    defaultValues = { transcript: '', duration: '00:00', notes: '' },
  }) => {
    // Prevent defaults or no changes
    if (
      (transcript === defaultValues.transcript || transcript === undefined) &&
      (duration === defaultValues.duration || duration === undefined) &&
      (notes === defaultValues.notes || notes === undefined)
    ) {
      toast.error('No changes or default values detected. Skipping update.');
      return;
    }

    toast.success('Changes detected. Updating call details...');
    console.log({
      transcript,
      duration,
      notes,
      email,
      userType,
    });
    
    dispatch(addCallDetails(transcript, duration, notes, email, userType));
  };

  return updateCallDetails;
};

export default useUpdateCallDetails;
