import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getRecentRatings, deleteFeedback } from '../services/operations/userStats';
import TablePagination  from './Table/TablePagination';
import { Trash2Icon } from 'lucide-react';

const RatingsTabs = () => {
    const [activeRating, setActiveRating] = useState(5);
    const [currentPage, setCurrentPage] = useState(1);

    const dispatch = useDispatch();

    const ratingData = useSelector((state) => {
        switch (activeRating) {
            case 1:
                return state.userStats.oneRated;
            case 2:
                return state.userStats.twoRated;
            case 3:
                return state.userStats.threeRated;
            case 4:
                return state.userStats.fourRated;
            case 5:
                return state.userStats.fiveRated;
            default:
                return state.userStats.fiveRated;
        }
    });

    const { data: recentRatings, totalRatings } = ratingData;

    const itemsPerPage = 10;
    const indexOfLastItem = currentPage * itemsPerPage;
    const indexOfFirstItem = indexOfLastItem - itemsPerPage;

    useEffect(() => {
        dispatch(getRecentRatings(activeRating, currentPage || 1));
    }, [activeRating, currentPage, dispatch]);

    const handleTabChange = (rating) => {
        setActiveRating(rating);
        dispatch(getRecentRatings(rating, 1)); // Reset to page 1 when switching tabs
    };

    const paginate = (pageNumber) => {
        setCurrentPage(pageNumber);
    };

    const handleDelete = (ratingId) => {
        if (window.confirm('Are you sure you want to delete this rating?')) {
            dispatch(deleteFeedback(ratingId))
                .then(() => {
                    dispatch(getRecentRatings(activeRating, currentPage));
                })
                .catch((err) => {
                    console.error('Failed to delete rating: ', err);
                });
        }
    }
    return (
        <div className="w-full">
            {/* Tabs Component */}
            <div className="flex space-x-4 mb-4">
                {[1, 2, 3, 4, 5].map((rating) => (
                    <button
                        key={rating}
                        className={`px-4 py-2 font-semibold ${
                            activeRating === rating
                                ? 'bg-brand text-white'
                                : 'bg-gray-200 text-gray-600'
                        } rounded-lg`}
                        onClick={() => handleTabChange(rating)}
                    >
                        {rating}-Star
                    </button>
                ))}
            </div>

            {/* Display recent ratings */}
            <div className="mt-4">
                {recentRatings.length > 0 ? (
                    <div>
                       {recentRatings.map((rating) => (
                            <div
                                key={rating.id}
                                className="border p-4 mb-2 rounded shadow-md flex items-center justify-between"
                            >
                                <div className="flex-1 flex flex-col">
                                    <div className="flex justify-between mb-2">
                                        <span className="text-xs text-gray-400">{rating.email}</span>
                                        <span className="text-xs text-gray-400">{new Date(rating.created_at).toLocaleString()}</span>
                                    </div>

                                    <div className="dark:text-gray-300 text-sm whitespace-pre-wrap overflow-auto">
                                        {rating.feedback}
                                    </div>
                                </div>

                                <button
                                    onClick={() => handleDelete(rating.id)}
                                    className="text-red-500 hover:text-red-700 ml-4"
                                    title="Delete rating"
                                >
                                    <Trash2Icon />
                                </button>
                            </div>
                        ))}

                        <div className="  border dark:border-dark_40 bg-[#F4F6F8] dark:bg-[#141414] border-gray-200  rounded-b-2xl mt-3">
                            <TablePagination
                                itemsPerPage={itemsPerPage}
                                totalItems={totalRatings}
                                currentPage={currentPage}
                                paginate={paginate}
                                indexOfFirstItem={indexOfFirstItem}
                                indexOfLastItem={indexOfLastItem}
                            />
                        </div>
                    </div>
                ) : (
                    <p>No ratings found for rating {activeRating}.</p>
                )}
            </div>
        </div>
    );
};

export default RatingsTabs;
