import { useState } from 'react';
import { useSelector } from 'react-redux';
import { Controller, useForm } from 'react-hook-form';
import CategoryTable from '../../components/Table/CategoryTable/CategoryTable';
import CategoryModal from '../../components/Shared/Modals/CategoryModal';
import Header from '../../components/Header/Header';
import Sidebar from '../../components/Sidebar/Sidebar';
import { useDispatch } from 'react-redux';
import { addTopic, deleteTopic, updateTopic } from '../../services/operations/new-syllabus';
import { setSubject } from '../../slices/new-syllabusSlice';

const AllSubject = () => {
    const [sidebarOpen, setSidebarOpen] = useState(false);
    const { Subject, AllSubjects } = useSelector((state) => state.new_syllabus);
    const [modalOpen, setModalOpen] = useState(false);
    const [isEdit, setIsEdit] = useState(false);

    const {
        handleSubmit,
        control,
        setValue,
        formState: { errors, isSubmitted },
        reset,
    } = useForm({
        defaultValues: {},
    });

    const dispatch = useDispatch();

    const handleAddProblem = (e) => {
        e.stopPropagation();
        setIsEdit(false);
        dispatch(setSubject({}));
        reset({
            name: '',
            slug: '',
            type: 'CategoryOnly',

        });
        setModalOpen(true);
    };

    

    const onSubmit = (data) => {
        const { name, slug, type } = data;

        if (isEdit) {
            const { id } = Subject;
            // dispatch(updateTheoryProblem(data, setModalOpen, reset));
            dispatch(updateTopic({ id, name, slug, type }, setModalOpen, isProd));
            return;
        } else {
            dispatch(addTopic({ name, slug, type }, setModalOpen, reset, isProd));
        }
        
    };

    const handleSubjectEdit = (e,subjectData) => {
        
        e.stopPropagation()
        const { id, name, slug, type } = subjectData
        const data = {
            id,
            name,
            slug,
            type
        }
        dispatch(setSubject(data))
        setModalOpen(true)
        setIsEdit(true)
         
        reset(data)
    }

    const { isProd } = useSelector((state) => state.auth);
    const handleDeleteSubject = (subject_slug,setDeleteModalOpen) => {
        dispatch(deleteTopic(subject_slug, isProd));
        setDeleteModalOpen(false)

    }


    return (
        <div className='flex dark:bg-dark font-primary  h-screen overflow-hidden bg-[#fafafa]'>
            <Sidebar sidebarOpen={sidebarOpen} setSidebarOpen={setSidebarOpen} />
            <div className='relative flex flex-col flex-1 overflow-y-auto overflow-x-hidden'>
                <Header sidebarOpen={sidebarOpen} setSidebarOpen={setSidebarOpen} />
                <main>
                    <div className='px-4 mb-8 sm:px-6 lg:px-8 w-full max-w-9xl mx-auto'>
                        <div className='flex justify-between items-center px-6 py-4'>
                            <h2 className='text-2xl font-bold text-[#212121] dark:text-zinc-200'>
                                All Subject
                            </h2>
                        </div>
                        <div className='flex justify-between items-center px-6 py-4'>
                            <button
                                onClick={handleAddProblem}
                                className='bg-[#FFD3B5] dark:bg-zinc-800 text-[#212121] dark:text-zinc-200 font-semibold py-2 px-4 rounded-lg'
                            >
                                Add Subject
                            </button>
                        </div>

                        <div className='flex gap-y-2 flex-col justify-start'>
                            {AllSubjects.length > 0 ? (
                                <div className='flex flex-col p-4 '>
                                    <CategoryTable
                                        page='Subject'
                                        setIsEdit={setIsEdit}
                                        topics={AllSubjects}
                                        setModalOpen={setModalOpen}
                                        modalOpen={modalOpen}
                                        handleEdit={handleSubjectEdit}
                                        handleDelete={handleDeleteSubject}
                                    />
                                </div>
                            ) : (
                                <div className='flex justify-center items-center text-4xl dark:text-zinc-300'>
                                    No Subjects to show
                                </div>
                            )}
                        </div>
                        <CategoryModal
                            title='Add Subject'
                            setModalOpen={setModalOpen}
                            modalOpen={modalOpen}
                        >
                            <form className='mt-6' onSubmit={handleSubmit(onSubmit)}>
                                <div className='flex flex-col flex-wrap -mx-3 mb-4'>
                                    <span className='px-3 text-lg font-semibold text-light_30 mb-2 dark:text-zinc-300'>
                                        Name
                                    </span>
                                    <div className='w-full px-3'>
                                        <Controller
                                            name='name'
                                            control={control}
                                            render={({ field }) => (
                                                <input
                                                    id='name'
                                                    type='text'
                                                    name='name'
                                                    className='form-input w-full text-gray-300'
                                                    placeholder='name'
                                                    onChange={(e) => {
                                                        field.onChange(e);
                                                        const filteredValue = e.target.value
                                                            .toLowerCase()
                                                            .replace(/[\s/]/g, '-');
                                                        setValue('slug', filteredValue);
                                                    }}
                                                    value={field.value}
                                                />
                                            )}
                                            rules={{
                                                required: 'Name is required',
                                            }}
                                        />
                                        {isSubmitted && errors.name && (
                                            <p className='text-red-600 text-sm italic'>
                                                {errors.name.message}
                                            </p>
                                        )}
                                    </div>
                                </div>

                                <div className='flex flex-col flex-wrap -mx-3 mb-4'>
                                    <span className='px-3 text-lg font-semibold text-light_30 mb-2 dark:text-zinc-300'>
                                        Slug
                                    </span>
                                    <div className='w-full px-3'>
                                        <Controller
                                            name='slug'
                                            control={control}
                                            render={({ field }) => (
                                                <input
                                                    id='slug'
                                                    type='text'
                                                    name='slug'
                                                    className='form-input w-full text-gray-300'
                                                    placeholder='slug'
                                                    {...field}
                                                />
                                            )}
                                            rules={{
                                                validate: (value) => {
                                                    const validSlugRegex = /^[a-zA-Z0-9_-]+$/;
                                                    if (!validSlugRegex.test(value)) {
                                                        return 'Slug can only contain letters, numbers, underscores, and hyphens';
                                                    }
                                                    return true;
                                                },
                                            }}
                                        />
                                        {isSubmitted && errors.slug && (
                                            <p className='text-red-600 text-sm italic'>
                                                {errors.slug?.message}
                                            </p>
                                        )}
                                    </div>
                                </div>

                                <div className='flex flex-col flex-wrap -mx-3 mb-4'>
                                    <span className='px-3 text-lg font-semibold text-light_30 mb-2 dark:text-zinc-300'>
                                        Type
                                    </span>
                                    <div className='w-full px-3'>
                                        <Controller
                                            name='type'
                                            defaultValue='CategoryOnly'
                                            control={control}
                                            render={({ field }) => (
                                                <select
                                                    {...field}
                                                    className='bg-zinc-900 rounded-md w-full'>
                                                    <option value='CategoryOnly'>Categories Only</option>
                                                    <option value='CategoryAndSubCategory'>Categories And Subcategories</option>
                                                </select>
                                            )}
                                        />
                                        {isSubmitted && errors.slug && (
                                            <p className='text-red-600 text-sm italic'>
                                                {errors.type.message}
                                            </p>
                                        )}
                                    </div>
                                </div>

                                <div className='flex flex-wrap -mx-3 mt-6'>
                                    <div className='w-full px-3'>
                                        <button type='submit' className='btn-brand-2 w-full'>
                                            Submit
                                        </button>
                                    </div>
                                </div>
                            </form>
                        </CategoryModal>
                    </div>
                </main>
            </div>
        </div>
    );
};

export default AllSubject;
