/* eslint-disable no-unused-vars */
import { salesDashboardEndpoints } from "../api";
import { apiConnector } from "../apiConnector";
import toast from "react-hot-toast";
import { setLoading, setError, setUserDetails, setUserCalls, setCallDetailsRedux, setUserViaEmail, updateBuyingStatusRedux } from "../../slices/callsSlice";
import { redirect } from "react-router-dom";
const { GET_FAILED_USERS, GET_FAILED_USER_DETAILS, GET_DROPPED_USERS, GET_DROPPED_USER_DETAILS, UPDATE_CALL_STATUS, ADD_COUPON_CODE, ADD_CALL_DETAILS, FIND_USER_BY_EMAIL, GET_ALL_WHATSAPP_USERS, GET_WHATSAPP_USER_DETAILS, UPDATE_BUYING_STATUS, ADD_WHATSAPP_USER_DETAILS, ADD_WHATSAPP_USER } = salesDashboardEndpoints;

export const fetchFailedUsers = (category, startDate, endDate, page = 1) => {
    return async (dispatch) => {
        dispatch(setLoading(true));
        try {
            const API_QUERY = (category !== 'all') ? `${GET_FAILED_USERS}?page=${page}&startDate=${startDate}&endDate=${endDate}&category=${category}` : `${GET_FAILED_USERS}?page=${page}&startDate=${startDate}&endDate=${endDate}`;
            const response = await apiConnector("GET", API_QUERY);
            dispatch(setUserCalls(response.data));

        } catch (error) {
            dispatch(setError(error.response?.data?.error || "Error fetching calls"));
            toast.error("Error fetching calls");
        } finally {
            dispatch(setLoading(false));
        }
    };
};

export const fetchFailedUserDetails = (email) => {
    return async (dispatch) => {
        dispatch(setLoading(true));
        try {
            const response = await apiConnector("POST", GET_FAILED_USER_DETAILS, { email });
            dispatch(setUserDetails(response.data.userDetails))
            dispatch(setCallDetailsRedux(response.data.userDetails.call_details))
        } catch (error) {
            dispatch(setError(error.response?.data?.error || "Error fetching user details"));
            toast.error("Error fetching user details");
        } finally {
            dispatch(setLoading(false));
        }
    };
};

export const fetchDroppedUsers = (category, startDate, endDate, page = 1) => {
    return async (dispatch) => {
        dispatch(setLoading(true));
        try {
            const API_QUERY = (category !== 'all') ? `${GET_DROPPED_USERS}?page=${page}&startDate=${startDate}&endDate=${endDate}&category=${category}` : `${GET_DROPPED_USERS}?page=${page}&startDate=${startDate}&endDate=${endDate}`;
            const response = await apiConnector("GET", API_QUERY);
            dispatch(setUserCalls(response.data));
        } catch (error) {
            dispatch(setError(error.response?.data?.error || "Error fetching calls"));
            toast.error("Error fetching calls");
        } finally {
            dispatch(setLoading(false));
        }
    };
};

export const fetchDroppedUserDetails = (email) => {
    return async (dispatch) => {
        dispatch(setLoading(true));
        try {
            const response = await apiConnector("POST", GET_DROPPED_USER_DETAILS, { email });
            dispatch(setUserDetails(response.data.userDetails));
            dispatch(setCallDetailsRedux(response.data.userDetails.call_details));
        } catch (error) {
            dispatch(setError(error.response?.data?.error || "Error fetching user details"));
            toast.error(error.response?.data?.error || "Error fetching user details");
        } finally {
            dispatch(setLoading(false));
        }
    };
};

export const updateCallStatus = (email, userType, status) => {
    return async (dispatch) => {
        dispatch(setLoading(true));
        try {
            const response = await apiConnector("PUT", UPDATE_CALL_STATUS, { email, userType, status })
            toast.success("call status updated");
        } catch (error) {
            dispatch(setError(error.response?.data?.error || "Error updating call status"));
            toast.error("Error updating call status");
        } finally {
            dispatch(setLoading(false));
        }
    };
};

export const addCouponCode = (code, email, userType) => {
    return async (dispatch) => {
        dispatch(setLoading(true));
        try {
            const response = await apiConnector("POST", ADD_COUPON_CODE, { code, email, userType });
            toast.success("Coupn Code Added");
        } catch (error) {
            dispatch(setError(error.response?.data?.error || "Error adding coupon code"));
            toast.error("Error adding coupon code");
        } finally {
            dispatch(setLoading(false));
        }
    };
};

export const addCallDetails = (transcript, duration, notes, email, userType) => {
    return async (dispatch) => {

        dispatch(setLoading(true));
        try {
            await apiConnector("POST", ADD_CALL_DETAILS, { transcript, duration, notes, email, userType });

            dispatch(setCallDetailsRedux([{ transcript: transcript, duration: duration, notes: notes }]))

            toast.success("Call Details Updated");
        } catch (error) {
            dispatch(setError(error.response?.data?.error || "Error adding call details"));
            toast.error("Error adding call details");
        } finally {
            dispatch(setLoading(false));
        }
    };
};

export const getUserByEmail = (email, userType) => {
    return async (dispatch) => {
        console.log(email);
        dispatch(setLoading(true));
        try {
            const response = await apiConnector("POST", FIND_USER_BY_EMAIL, { email, userType });
            dispatch(setUserViaEmail(response.data))
            toast.success("User found");
        } catch (error) {
            dispatch(setError(error.response?.data?.error || "Error Finding the user"));
            toast.error("Error finding the user");
        } finally {
            dispatch(setLoading(false));
        }
    };
};

export const fetchWhatsappUsers = (category, startDate, endDate, page = 1) => {
    return async (dispatch) => {
        dispatch(setLoading(true));
        try {
            const API_QUERY = (category !== 'all') ? `${GET_ALL_WHATSAPP_USERS}?page=${page}&startDate=${startDate}&endDate=${endDate}&category=${category}` : `${GET_ALL_WHATSAPP_USERS}?page=${page}&startDate=${startDate}&endDate=${endDate}`;
            const response = await apiConnector("GET", API_QUERY);
            dispatch(setUserCalls(response.data));
        } catch (error) {
            dispatch(setError(error.response?.data?.error || "Error fetching calls"));
            toast.error("Error fetching calls");
        } finally {
            dispatch(setLoading(false));
        }
    };
};

export const fetchWhatsappUserDetails = (email) => {
    return async (dispatch) => {
        dispatch(setLoading(true));
        try {
            const response = await apiConnector("POST", GET_WHATSAPP_USER_DETAILS, { email });
            dispatch(setUserDetails(response.data.userDetails));
            dispatch(setCallDetailsRedux(response.data.userDetails.call_details));
        } catch (error) {
            console.error("Error fetching user details:", error);

            dispatch(setError(error.response?.data?.error || "Error fetching user details"));
            toast.error(error.response?.data?.error || "Error fetching user details");
        } finally {
            dispatch(setLoading(false));
        }
    };
};

export const updateBuyingStatus = (email, status) => {
    return async (dispatch) => {
        dispatch(setLoading(true));
        try {
            const response = await apiConnector("PUT", UPDATE_BUYING_STATUS, { email, status })
            dispatch(updateBuyingStatusRedux(status))
            toast.success("call status updated");

        } catch (error) {
            dispatch(setError(error.response?.data?.error || "Error updating call status"));
            toast.error("Error updating call status");
        } finally {
            dispatch(setLoading(false));
        }
    };
};

export const addWhatsappUserDetails = (notes, email) => {
    return async (dispatch) => {
        dispatch(setLoading(true));
        try {
            const response = await apiConnector("PUT", ADD_WHATSAPP_USER_DETAILS, { notes, email })
            toast.success("user details updated");

        } catch (error) {
            dispatch(setError(error.response?.data?.error || "Error updating user details"));
            toast.error("Error updating user details");
        } finally {
            dispatch(setLoading(false));
        }
    };
};

export const addWhatsappUser = (email, name, phone, status, code) => {
    return async (dispatch) => {
        dispatch(setLoading(true));
        try {
            const response = await apiConnector("POST", ADD_WHATSAPP_USER, { email, name, phone, status, code })
            toast.success("Whatsapp User Added");

        } catch (error) {
            dispatch(setError(error.response?.data?.error || "Error adding whatsapp user"));
            toast.error("Error adding whatsapp user");
        } finally {
            dispatch(setLoading(false));
        }
    };
};