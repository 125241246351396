import React from 'react';
import SidebarLinkGroup from './SidebarLinkGroup';
import { NavLink, useLocation } from 'react-router-dom';
import { useEffect } from 'react';
import { File, Folder, FolderOpen } from 'lucide-react';

function SubcategoryLinkWrapper({ subcategories, pathname }) {
  return (
    <div>
      {subcategories.map((subcategory, index) => (
        <div key={index} className={`px-3 py-2 rounded-lg last:mb-0`}>
          <NavLink
            exact
            to={`/${subcategory.link}`}
            className={`block text-gray-200 hover:text-white truncate transition duration-150 ${
              pathname.includes(subcategory.link) && 'hover:text-gray-200 font-bold'
            }`}
          >
            <div className='flex items-center'>
              <File
                size={20}
                className={`${pathname.includes(subcategory.link) && 'stroke-brand_50'}`}
              />
              <span
                className={`text-sm font-medium ml-3 lg:opacity-0 lg:sidebar-expanded:opacity-100 2xl:opacity-100 duration-200 ${
                  pathname.includes(subcategory.link) && 'text-brand_50'
                }`}
              >
                {subcategory.name}
              </span>
            </div>
          </NavLink>
        </div>
      ))}
    </div>
  );
}


function SidebarLinkGroupWrapper({ sidebarCategoryLinks, storedSidebarExpanded, sidebarExpanded, setSidebarExpanded}) {
  const location = useLocation();
  const { pathname } = location;

  useEffect(() => {
    localStorage.setItem('sidebar-expanded', sidebarExpanded);
    if (sidebarExpanded) {
      document.querySelector('body').classList.add('sidebar-expanded');
    } else {
      document.querySelector('body').classList.remove('sidebar-expanded');
    }
  }, [sidebarExpanded]);
  
  return (
    <SidebarLinkGroup activecondition={pathname.includes(sidebarCategoryLinks.category.link)}>
      {(handleClick, open) => {
        return (
          <>
            <a
              href='#0'
              className={`block text-gray-200 hover:text-white truncate transition-all ease-in-out duration-300 ${pathname.includes(sidebarCategoryLinks.category.link) && 'hover:text-gray-200  '}`}
              onClick={(e) => {
                e.preventDefault();
                sidebarExpanded ? handleClick() : setSidebarExpanded(true);
              }}
            >
              <div className='flex items-center justify-between '>
                <div className='flex items-center '>
                  {open ? (
                    <FolderOpen
                      className={`text-gray-400 ${pathname.includes(sidebarCategoryLinks.category.link) && '!text-orange-600'}`}
                    />
                  ) : (
                    <Folder
                      className={`text-gray-400 ${pathname.includes(sidebarCategoryLinks.category.link) && '!text-orange-600'}`}
                    />
                  )}
                  <span
                    className={`text-sm  ml-3 lg:opacity-0 lg:sidebar-expanded:opacity-100 2xl:opacity-100 duration-200 ${pathname.includes(sidebarCategoryLinks.category.link) ? 'text-brand font-bold ' : 'font-medium'}`}
                  >
                    {sidebarCategoryLinks.category.name}
                  </span>
                </div>
                <div className='flex flex-shrink-0 ml-2'>
                  <svg
                    className={`w-3 h-3 flex-shrink-0 ml-1 fill-current text-gray-400 ${open && 'transform rotate-180'}`}
                    viewBox='0 0 12 12'
                  >
                    <path d='M5.9 11.4L.5 6l1.4-1.4 4 4 4-4L11.3 6z' />
                  </svg>
                </div>
              </div>
            </a>
            <div className='lg:hidden  lg:sidebar-expanded:block 2xl:block'>
              <div className={`pl-3  mt-1 ${!open && 'hidden'}`}>
                <div className='py-2 flex relative'>
                  <div>
                    <SubcategoryLinkWrapper
                      subcategories={sidebarCategoryLinks.subcategories}
                      pathname={pathname}
                    />
                  </div>
                </div>
              </div>
            </div>
            <p />
          </>
        );
      }}
    </SidebarLinkGroup>
  );
}

export default SidebarLinkGroupWrapper;
