import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { getAverageRatings } from '../../services/operations/userStats';
import { PieChart, Pie, Cell, Tooltip } from 'recharts';

const LIGHT_MODE_COLORS = ['#FF8042', '#FFBB28', '#0088FE', '#00C49F', '#FF8042'];
const DARK_MODE_COLORS = ['#FF6347', '#FFA500', '#1E90FF', '#20B2AA', '#FF4500'];

const RatingsChart = () => {
    const dispatch = useDispatch();
    
    const { averageRating, ratingDistribution } = useSelector((state) => state.userStats);
    
    const isDarkMode = window.matchMedia && window.matchMedia('(prefers-color-scheme: dark)').matches;
    const COLORS = isDarkMode ? DARK_MODE_COLORS : LIGHT_MODE_COLORS;

    useEffect(() => {
        dispatch(getAverageRatings());
    }, [dispatch]);

    const totalCount = ratingDistribution.reduce((sum, item) => sum + item.count, 0);
    const chartData = ratingDistribution.map((item) => ({
        rating: item.rating,
        count:  item.count,
        percentage: parseFloat(item.percentage),
    }));

    const CustomTooltip = ({ active, payload }) => {
        if (active && payload && payload.length) {
            return (
                <div className="custom-tooltip bg-gray-700 text-white p-2 rounded">
                    <p>{`Rating: ${payload[0].name}`}</p>
                    <p>{`Percentage: ${payload[0].value.toFixed(2)}%`}</p>
                </div>
            );
        }

        return null;
    };

    return (
<div className="flex flex-col items-center justify-center mt-8">
    <div className="flex flex-col items-center justify-center">
        {/* Chart and Card (Average Rating + Legend) Layout */}
        <div className="flex justify-center items-center">

            {/* Average Rating and Legend Card */}
            <div className="mr-16 bg-gray-500 shadow-md rounded-md p-4 text-white w-60">
                <div className="text-center mb-4">
                    {/* Average Rating with Percentage */}
                    <p className="text-lg font-semibold">{`Average Rating: ${parseFloat(averageRating).toFixed(2)}`}</p>
                    <span>({totalCount} reviews)</span>
                </div>

                {/* Legend with Ratings and Percentages */}
                <div className="flex flex-col items-start">
                    {chartData.map((entry, index) => (
                        <div key={`legend-${index}`} className="flex items-center mb-2">
                            <div
                                className="w-4 h-4 mr-2"
                                style={{ backgroundColor: COLORS[index % COLORS.length] }}
                            />
                            {/* Display rating and percentage together */}
                            <span className="text-sm">{`${entry.rating} star: ${entry.percentage.toFixed(2)}% (${entry.count} reviews)`}</span>
                        </div>
                    ))}
                </div>         
            </div>

            {/* Pie Chart */}
            <PieChart width={400} height={400}>
                <Pie
                    data={chartData}
                    dataKey="percentage"
                    nameKey="rating"
                    cx="50%"
                    cy="50%"
                    outerRadius={150}
                    fill="#8884d8"
                >
                    {chartData.map((entry, index) => (
                        <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
                    ))}
                </Pie>
                <Tooltip content={<CustomTooltip />} />
            </PieChart>
        </div>
    </div>
</div>

    );
};

export default RatingsChart;
