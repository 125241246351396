import React from 'react'
import ConfigsItem from './ConfigsItem'

const ConfigsTable = ({ configs, setModalOpen, setIsEdit, isProd }) => {
    return (
        <div className=" font-primary  relative w-full overflow-x-auto">
            <div className=' bg-white dark:bg-dark_50  rounded-t-xl'>
                <div className="overflow-x-auto">
                    <table className="table-auto w-full max-w-full divide-y  divide-gray-200">
                        <thead className="text-xs uppercase  text-[#8C8C8C] ">
                            <tr>
                                <th className="px-2 border-2 border-lead100 dark:border-lead500 first:pl-5 last:pr-5 py-3 whitespace-nowrap">
                                    <div className="font-semibold text-center">Key</div>
                                </th>
                                <th className="px-2 border-2 border-lead100 dark:border-lead500 first:pl-5 last:pr-5 py-3 whitespace-nowrap">
                                    <div className="font-semibold text-center">Value</div>
                                </th>
                                <th className="px-2 border-2 border-lead100 dark:border-lead500 first:pl-5 last:pr-5 py-3 whitespace-nowrap">
                                    <div className="font-semibold text-center">Action</div>
                                </th>
                            </tr>
                        </thead>
                        {
                            configs?.map((data) => {
                                return (
                                    <ConfigsItem
                                        key={data?.id}
                                        id={data?.id}
                                        ConfigKey={data?.config_name}
                                        value={data?.config_flag_value}
                                        setModalOpen={setModalOpen}
                                        setIsEdit={setIsEdit}
                                        isProd={isProd}
                                    />
                                )
                            })
                        }  
                    </table>
                </div>
            </div>
        </div>
    )
}

export default ConfigsTable


