import { useEffect, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useSelector, useDispatch } from 'react-redux';
import Accordion from '../../../components/Accordion/Accordion';
import { BadgeX } from 'lucide-react';
import { getCopyToEditorCode, updateDSAProblem } from '../../../services/operations/new-syllabus';

const EditorialEdit = (props) => {
  const { CurrentProblem } = useSelector((state) => state.new_syllabus);
  const dispatch = useDispatch();
  const { isProd } = useSelector((state) => state.auth);
  const [activeTab, setActiveTab] = useState(0);
  const [editorials, setEditorials] = useState([]);
  const [copyToEditorCode, setCopyToEditorCode] = useState([]);

  const {
    handleSubmit,
    reset,
    control,
    formState: { errors },
  } = useForm({
    defaultValues: {
      editorial: CurrentProblem?.new_editorial || [],
      copy_to_editor_code: CurrentProblem?.copy_to_editor_code || [],
    },
  });

  useEffect(() => {
    if (CurrentProblem?.new_editorial) {
      setEditorials(CurrentProblem?.new_editorial);
      setCopyToEditorCode(CurrentProblem?.copy_to_editor_code || []);
      reset({ 
        editorial: CurrentProblem?.new_editorial,
        copy_to_editor_code: CurrentProblem?.copy_to_editor_code 
      });
    }
  }, [CurrentProblem, reset]);

  const handleKeyChange = (index, newKey) => {
    const updatedEditorials = editorials.map((editorial, i) =>
      i === index ? { ...editorial, name: newKey } : editorial,
    );
    setEditorials(updatedEditorials);

    // Update copy_to_editor_code name
    const oldName = editorials[index].name;
    const updatedCopyToEditor = copyToEditorCode.map(code =>
      code.name === oldName ? { ...code, name: newKey } : code
    );
    setCopyToEditorCode(updatedCopyToEditor);
  };

  const handleValueChange = (index, newValue) => {
    const updatedEditorials = editorials.map((editorial, i) =>
      i === index ? { ...editorial, editorial: newValue } : editorial,
    );
    setEditorials(updatedEditorials);
  };

  const handlePlaceholderChange = (index, newPlaceholder) => {
    const updatedEditorials = editorials.map((editorial, i) =>
      i === index ? { ...editorial, asset_id: newPlaceholder } : editorial,
    );
    setEditorials(updatedEditorials);
  };

  const handleEditorCodeChange = (editorialName, language, newValue) => {
    setCopyToEditorCode(prevCodes => {
      const codeIndex = prevCodes.findIndex(code => code.name === editorialName);
      
      if (codeIndex === -1) {
        return [...prevCodes, {
          name: editorialName,
          editorial: {
            cpp: language === 'cpp' ? newValue : '',
            java: language === 'java' ? newValue : '',
            python: language === 'python' ? newValue : '',
            javascript: language === 'javascript' ? newValue : ''
          }
        }];
      }

      const updatedCodes = [...prevCodes];
      updatedCodes[codeIndex] = {
        ...updatedCodes[codeIndex],
        editorial: {
          ...updatedCodes[codeIndex].editorial,
          [language]: newValue
        }
      };
      return updatedCodes;
    });
  };

  const tabs = ["cpp", "java", "python", "javascript"];

  const onSubmit = (data) => {

    const problem_data = {
      type: 'editorial',
      id: CurrentProblem.id,
      slug: CurrentProblem.slug,
      new_editorial: editorials,
      copy_to_editor_code: copyToEditorCode
    };

    dispatch(updateDSAProblem(problem_data, isProd));
  };

  const generateCode = async (e)=>{
        e.preventDefault();
        e.stopPropagation();
        dispatch(getCopyToEditorCode(CurrentProblem.slug, setCopyToEditorCode))
  }

  return (
    <div className='p-4'>
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className='flex flex-col gap-4'>
          {editorials.length > 0 ? (
            editorials.map((value, index) => (
              <div key={index} className='flex items-center gap-2'>
                <div className='w-full'>
                  <Accordion key={index} title={value?.name}>
                    <div className='flex flex-col items-start gap-x-4 mb-2'>
                    <label htmlFor="asset_id" className='mb-2 font-bold'>Solution Name: Ex (Brute, Optimal etc)</label>
                      <input
                        type='text'
                        value={value?.name}
                        onChange={(e) => handleKeyChange(index, e.target.value)}
                        className='form-input w-1/3 text-gray-800 border rounded p-2'
                        placeholder='Editorial Key'
                      />
                    </div>

                    <div className='flex flex-col items-start gap-x-4 mb-2'>
                    <label htmlFor="asset_id" className='mb-2 font-bold'>Asset Id: Video Asset Id</label>
                      <input
                        type='text'
                        value={value?.asset_id}
                        onChange={(e) => handlePlaceholderChange(index, e.target.value)}
                        className='form-input w-1/3 text-gray-800 border rounded p-2'
                        placeholder='Asset Id'
                        name='asset_id'
                      />
                    </div>

                    <div className='flex flex-col items-start gap-x-4 mb-2'>
                    <label htmlFor={`editorial[${index}].editorial`} className='mb-2 font-bold'>Editorial Data</label>
                      <Controller
                        name={`editorial[${index}].editorial`}
                        control={control}
                        render={({ field }) => (
                          <textarea
                            value={value?.editorial}
                            onChange={(e) => handleValueChange(index, e.target.value)}
                            className='form-input w-full text-gray-800 border rounded p-2 h-80'
                            placeholder='Editorial Value'
                          />
                        )}
                      />
                      <div className='w-full mt-4'>
                        <Accordion key={value?.name} title={"Copy to Editor Code"}>
                          <div className='flex gap-2 border-b mb-4'>
                            {tabs.map((lang, tabIndex) => (
                              <button
                                key={lang}
                                type='button'
                                onClick={() => setActiveTab(tabIndex)}
                                className={`p-2 px-4 capitalize ${
                                  activeTab === tabIndex 
                                    ? 'border-b-2 border-blue-500 text-blue-500 font-medium' 
                                    : 'text-gray-600'
                                }`}
                              >
                                {lang}
                              </button>
                            ))}
                          </div>
                          <div className='w-full'>
                            <textarea
                              value={copyToEditorCode.find(code => code.name === value.name)?.editorial?.[tabs[activeTab]] || ''}
                              rows={20}
                              onChange={(e) => handleEditorCodeChange(value.name, tabs[activeTab], e.target.value)}
                              className='form-input w-full text-gray-800 border rounded p-2'
                              placeholder={`${tabs[activeTab]} code for ${value.name}`}
                            />
                          </div>
                        </Accordion>
                      </div>
                    </div>

                    {errors?.editorial?.[index] && (
                      <span className='text-red-500'>{errors.editorial[index]?.message}</span>
                    )}
                  </Accordion>
                </div>
                <BadgeX
                  onClick={() => {
                    const updatedEditorials = editorials.filter((_, i) => i !== index);
                    setEditorials(updatedEditorials);
                    // Also remove corresponding copy_to_editor_code
                    const updatedCopyToEditor = copyToEditorCode.filter(code => code.name !== value.name);
                    setCopyToEditorCode(updatedCopyToEditor);
                  }}
                  className='cursor-pointer text-red-500'
                />
              </div>
            ))
          ) : (
            <div className='flex items-center justify-center text-gray-500'>
              No editorials added yet.
            </div>
          )}
          <button
            type='button'
            className='p-2 rounded bg-blue-500 text-white w-32'
            onClick={() => {
              const newName = '';
              setEditorials([...editorials, { name: newName, editorial: '', asset_id: '' }]);
              setCopyToEditorCode([...copyToEditorCode, {
                name: newName,
                editorial: {
                  cpp: '',
                  java: '',
                  python: '',
                  javascript: ''
                }
              }]);
            }}
          >
            Add Editorial
          </button>
          <div className='mt-4 flex gap-10'>
            <button type='submit' className='p-2 rounded bg-blue-500 text-white w-32'>
              Save
            </button>
            <button type='button' onClick={generateCode} className='p-2 rounded bg-blue-500 text-white w-32'>
              Generate Copy To Editor
            </button>
            <button
              type='button'
              className='p-2 rounded bg-blue-500 text-white w-32'
              onClick={() => {
                localStorage.setItem('editorialData', JSON.stringify(editorials));
                window.open('/manage-content/problem/new-preview', '_blank');
              }}
            >
              Preview
            </button>
          </div>
        </div>
      </form>
    </div>
  );
};

export default EditorialEdit;