import { setAllUsers } from "../../slices/grantAccessSlice"
import { grantAccessEndpoints} from "../api"
import { apiConnector } from "../apiConnector"
import toast from 'react-hot-toast'

const { GET_ALL_USERS, ADD_USER, ADD_USER_DEV, GRANT_USER_ACCESS, EDIT_USER, DELETE_USER, REMOVE_PREMIUM_ACCESS } = grantAccessEndpoints;

export function getAllUsers() {
    return async(dispatch) => {
        try {
            const response = await apiConnector("GET", GET_ALL_USERS);
            if (response.data.success){
                dispatch(setAllUsers(response.data.all_users));
            }
        } catch (error) {
            console.log(error);
            toast.error(error.response?.data?.message || "An error occurred");
        }
    }
}

export function addUser(email, name, username, isPremium, plan_name, validity, medium, setModalOpen, isProd) {
    return async (dispatch) => {
        try {
            if(isProd) {
                const response = await apiConnector("POST", ADD_USER, { email, name, username, isPremium, plan_name, validity, medium })
                const response2 = await apiConnector("POST", ADD_USER_DEV, { email, name, username, isPremium, plan_name, validity, medium })
                if (response.data.success && response2.data.success) {
                    toast.success(response.data.message);
                    dispatch(getAllUsers());
                    if(setModalOpen) {
                        setModalOpen(false);
                    }
                } else {
                    toast.error(response.data.message || "Failed to add user");
                }
            } else {
                const response = await apiConnector("POST", ADD_USER_DEV, { email, name, username, isPremium, plan_name, validity, medium })
                if (response.data.success) {
                    toast.success(response.data.message);
                    dispatch(getAllUsers());
                    if(setModalOpen) {
                        setModalOpen(false);
                    }
                } else {
                    toast.error(response.data.message || "Failed to add user");
                }
            }
        } catch (error) {
            console.log(error)
            toast.error(error.response?.data?.message || "An error occurred");
        }
    }
}

export function grantUserAccess(email, name, username, isPremium, plan_name, validity, medium,amount, location, phoneNumber, currency, setGrantAccessModal) {
    return async (dispatch) => {
        try {
            const response = await apiConnector("POST", GRANT_USER_ACCESS, { email, name, username, isPremium, plan_name, validity, medium, amount, location, phoneNumber, currency })
            if (response.data.success) {
                toast.success(response.data.message);
                if(setGrantAccessModal) {
                    setGrantAccessModal(false);
                }
            } else {
                toast.error(response.data.message || "Failed to add user");
            }
        } catch (error) {
            console.log(error)
            toast.error(error.response?.data?.message || "An error occurred");
        }
    }
}

export function updateUser(email, name, isPremium, plan_name, validity, medium, setModalOpen ) {
    return async (dispatch) => {
        try {
            const response = await apiConnector("PUT", EDIT_USER , { email, name, isPremium, plan_name, validity, medium });
            if (response.data.success) {
                toast.success(response.data.message);
                dispatch(getAllUsers());
                if(setModalOpen) {
                    setModalOpen(false);
                }
            } else {
                toast.error(response.data.message || "Failed to update user");
            }
        } catch (error) {
            console.log(error);
            toast.error(error.response?.data?.message || "An error occurred");
        }
    }
}

export function deleteUser(id, setModalDeleteOpen) {
    return async (dispatch) => {
        try {
            const response = await apiConnector("DELETE", DELETE_USER + `${id}`);
            if(response.data.success) {
                dispatch(getAllUsers());
                toast.success(response.data.message);
                setModalDeleteOpen(false);
            } else {
                toast.error(response.data.message || "Failed to delete user");
            }
        } catch (error) {
            console.log(error);
            toast.error(error.response?.data?.message || "An error occurred");
        }
    }
}

export function removePremiumAccess(email, setModalDeleteOpen) {
    return async (dispatch) => {
        try {
            const response = await apiConnector("DELETE", REMOVE_PREMIUM_ACCESS , { email });
            if(response.data.success) {
                dispatch(getAllUsers());
                toast.success(response.data.message);
                setModalDeleteOpen(false);
            } else {
                toast.error(response.data.message || "Failed to delete user");
            }
        } catch (error) {
            console.log(error);
            toast.error(error.response?.data?.message || "An error occurred");
        }
    }
}