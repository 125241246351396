/* eslint-disable no-unused-vars */
import React, { useEffect, useRef, useState } from "react";
import Transition from "../../../utils/Transition";
import { successStory } from "../../../services/operations/successStories";
import { X } from "lucide-react";
import toast from "react-hot-toast";
import { useForm, Controller } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';

function SuccessStoryModal({ modalOpen, setModalOpen }) {
  const modalContent = useRef(null);
  const dispatch = useDispatch();

  const [isSending, setIsSending] = useState(false);
  const [image, setImage] = useState(null);
  const [imageName, setImageName] = useState("");
  const [imagePreview, setImagePreview] = useState(image);

  useEffect(() => {
    const clickHandler = ({ target }) => {
      if (!modalOpen || modalContent.current.contains(target)) return;
      setModalOpen(false);
    };
    document.addEventListener("click", clickHandler);
    return () => document.removeEventListener("click", clickHandler);
  });

  useEffect(() => {
    const keyHandler = ({ keyCode }) => {
      if (!modalOpen || keyCode !== 27) return;
      setModalOpen(false);
    };
    document.addEventListener("keydown", keyHandler);
    return () => document.removeEventListener("keydown", keyHandler);
  });

  const handleFileChange = (event) => {
    const file = event.target.files[0];
    if (file) {
        if (file.size > 1000 * 1024) { // 1MB
            toast.error("File size should be less than 1 MB.");
            return;
        }
        setImage(file);
        setImageName(file.name);
        setImagePreview(URL.createObjectURL(file)); // Set preview URL for image
    }
  };

  const handleDeleteImage = (e) => {
      e.stopPropagation();
      e.preventDefault();
      setImage(null);
      setImageName("");
      setImagePreview(null);
  };

  const { control, handleSubmit, formState: { errors, isSubmitted }, reset } = useForm();

  const handleOnSubmit = async (data) => {
    setIsSending(true);
    try {
        dispatch(successStory({ ...data, setModalOpen, image}));
        setModalOpen(false);
        reset({
          story: "",
          status: "",
          email: "",
          linkedIn: ""
        });
        setImage(null); // Clear image on submit
        setImagePreview(null); // Clear preview
    } catch (error) {
        toast.error('An error occurred while sending request.');
    } finally {
        setIsSending(false);  
    }
  };

  return (
    <>
      <Transition
        className="fixed inset-0 bg-gray-900 bg-opacity-30 z-50 transition-opacity"
        show={modalOpen}
        enter="transition ease-out duration-200"
        enterStart="opacity-0"
        enterEnd="opacity-100"
        leave="transition ease-out duration-100"
        leaveStart="opacity-100"
        leaveEnd="opacity-0"
        aria-hidden="true"
      />
      <Transition
        className="fixed inset-0 z-50 flex items-center justify-center transform px-4 sm:px-6"
        role="dialog"
        aria-modal="true"
        show={modalOpen}
        enter="transition ease-in-out duration-200"
        enterStart="opacity-0 translate-y-4"
        enterEnd="opacity-100 translate-y-0"
        leave="transition ease-in-out duration-200"
        leaveStart="opacity-100 translate-y-0"
        leaveEnd="opacity-0 translate-y-4"
      >
        <div
          ref={modalContent}
          className="bg-white dark:bg-dark_50 rounded-xl shadow-xl overflow-y-auto no-scrollbar mt-10 max-w-2xl w-full h-fit max-h-full  border border-gray-200 dark:border-zinc-800"
        >

          <div className="px-5 py-3">
            <div className="flex justify-between items-center">
              <div className="font-semibold text-xl text-zinc-800 dark:text-zinc-100 ">Share success story</div>
              <button
                onClick={(e) => {
                  e.stopPropagation();
                  setModalOpen(false);
                }}
              >
                <X className="text-zinc-800 dark:text-zinc-100"/>
              </button>
            </div>

        <div className='w-[90%] mx-auto mt-4 mb-12 md:p-0 p-8  relative'>
            <form onSubmit={handleSubmit(handleOnSubmit)} className="mt-6">
                <div className="flex flex-col flex-wrap -mx-3 mb-4">
                  <span className="px-3 text-md font-semibold text-new_primary dark:text-new_dark_primary mb-2">
                    Share how TUF+ helped you succeed!
                  </span>
                  <div className="w-full px-3 ">
                    <Controller
                      name="story"
                      control={control}
                      render={({ field }) => (
                        <textarea
                          id="story"
                          name="story"
                          className="form-textarea w-full px-2 py-1 h-60"
                          rows="6"
                          placeholder="Tell us about your experience ..."
                          {...field}
                        />
                      )}
                      rules={{
                        required: "story is required",
                        minLength: {
                          value: 10,
                          message: "story must be at least 50 characters",
                        },
                      }}
                    />
                    {isSubmitted && errors.story && (
                      <p className="text-red-600 text-sm italic">
                        {errors.story.message}
                      </p>
                    )}
                  </div>
                </div>

                <div className="flex flex-col flex-wrap -mx-3 mb-4">
                  <span className="px-3 text-md font-semibold text-new_primary dark:text-new_dark_primary mb-2">
                    How would you like to share your story?
                  </span>
                  <div className="w-full px-3">
                    <Controller
                      name="status"
                      control={control}
                      render={({ field }) => (
                        <select
                          id="status"
                          name="status"
                          className="form-select v2form-textarea w-full text-new_secondary dark:text-new_dark_secondary"
                          {...field}
                        >
                          <option value="">Select Status</option>
                          <option value="public">Allow us to share publicly</option>
                          <option value="private">Allow us to share this by mentioning anonymous</option>
                          <option value="do not share">Do not share this publicly anywhere</option>
                        </select>
                      )}
                      rules={{
                        required: "status is required",
                      }}
                    />
                    {errors.status && (
                      <p className="text-red-600 text-sm italic">
                        {errors.status.message}
                      </p>
                    )}
                  </div>
                </div>

                <div className="flex flex-col flex-wrap -mx-3 mb-4">
                  <span className="px-3 text-md font-semibold text-new_primary dark:text-new_dark_primary mb-2">
                    User's Email
                  </span>
                  <div className="w-full px-3">
                    <Controller
                      name="email"
                      control={control}
                      render={({ field }) => (
                        <input
                          type="text"
                          id="email"
                          name="email"
                          className="form-input w-full"
                          placeholder="Add user's email here ..."
                          {...field}
                      />
                      )}
                      rules={{
                        required: "email is required",
                      }}
                    />
                    {errors.email && (
                      <p className="text-red-600 text-sm italic">
                        {errors.email.message}
                      </p>
                    )}
                  </div>
                </div>

                <div className="flex flex-col flex-wrap -mx-3 mb-4">
                  <span className="px-3 text-md font-semibold text-new_primary dark:text-new_dark_primary mb-2">
                    LinkedIn URL
                  </span>
                  <div className="w-full px-3">
                    <Controller
                      name="linkedIn"
                      control={control}
                      render={({ field }) => (
                        <input
                          type="text"
                          id="linkedIn"
                          name="linkedIn"
                          className="form-input w-full"
                          placeholder="Share your LinkedIn profile with us ..."
                          {...field}
                      />
                      )}
                    />
                    {errors.linkedIn && (
                      <p className="text-red-600 text-sm italic">
                        {errors.linkedIn.message}
                      </p>
                    )}
                  </div>
                </div>

                {/* Image Upload Section */}
                <div className="flex flex-col flex-wrap -mx-3 mb-4">
                <span className="px-3 text-md font-semibold text-new_primary dark:text-new_dark_primary mb-2">Upload User's Image (optional)</span>
                    <div className="w-full px-3">
                        {imagePreview && (
                            <div className="flex items-center gap-4">
                                <img src={imagePreview} alt="Preview" className=" ml-10 w-24 h-24 rounded-lg object-cover" />
                                <div className="flex flex-row gap-x-4 justify-between items-center">
                                    <p className="text-sm text-gray-500 dark:text-gray-400">{imageName}</p>
                                    <button
                                        onClick={handleDeleteImage}
                                        type="button"
                                        className="text-red-500 text-xs hover:underline mt-1"
                                    >
                                        <div className={`font-medium rounded-full w-fit text-center bg-red-200 dark:bg-[#470C08] px-4 py-1 text-red-500 text-sm`}>
                                            Remove
                                        </div>
                                    </button>
                                </div>
                            </div>
                        )}
                        {!imagePreview && (
                            <label
                                htmlFor='dropzone-file'
                                className='flex flex-col items-center justify-center w-full h-24 border-2 border-gray-300 dark:border-zinc-600 border-dashed rounded-lg cursor-pointer bg-gray-50 dark:bg-dark_50 dark:hover:bg-gray-800'
                            >
                                <div className='flex flex-col items-center justify-center pt-5 pb-6'>
                                    <svg
                                        className='w-4 h-4 mb-3 text-gray-400'
                                        fill='none'
                                        stroke='currentColor'
                                        viewBox='0 0 24 24'
                                        xmlns='http://www.w3.org/2000/svg'
                                    >
                                        <path
                                            strokeLinecap='round'
                                            strokeLinejoin='round'
                                            strokeWidth='2'
                                            d='M7 16a4 4 0 01-.88-7.903A5 5 0 1115.9 6L16 6a5 5 0 011 9.9M15 13l-3-3m0 0l-3 3m3-3v12'
                                        ></path>
                                    </svg>
                                    <p className='mb-2 text-sm text-gray-500 dark:text-gray-400'>
                                        <span className='font-semibold'>Click to upload</span> or drag and drop
                                    </p>
                                    <p className='text-xs text-gray-500 dark:text-gray-400'>PNG, JPG or JPEG (Max. 1MB)</p>
                                </div>
                                <input
                                    id='dropzone-file'
                                    className='hidden'
                                    type='file'
                                    name='image'
                                    accept='image/*'
                                    onChange={handleFileChange}
                                />
                            </label>
                        )}
                    </div>
                </div>

                <div className="w-full ">
                  <button
                    type="submit"
                    className="bg-brand w-full rounded-[4px] py-2 text-white"
                  >
                    Add Story
                  </button>
                </div>
            </form>
          </div>
          </div>
        </div>
      </Transition>
    </>
  );
}

export default SuccessStoryModal;
