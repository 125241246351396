import { useEffect, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { updateDSAProblem } from '../../../services/operations/new-syllabus';
import { BadgeX, Plus } from 'lucide-react';
import Accordion from '../../../components/Accordion/Accordion';
import toast from 'react-hot-toast';
import { encoderFunction } from '../../SyllabusContent/ProblemSetter/encoderFunction';

const ContentEdit = (props) => {
    const { CurrentProblem } = useSelector((state) => state.new_syllabus);
    const {
        control: controlIO,
        register: registerIO,
        watch: watchIO,
        handleSubmit: handleSubmitIO,
        setValue: setValueIO,
        reset: resetIO,
        formState: { errors: errorsIO },
    } = useForm({
        defaultValues: {},
    });

    const [encoderError, setEncoderError] = useState(null);

    // eslint-disable-next-line
    const [output, setOutput] = useState([
        {
            input: 'Encoded Input Value',
        },
    ]);

    const problemLogicalData = watchIO('problemLogicalData');

    useEffect(() => {
        if (Object.keys(CurrentProblem).length > 0) {
            resetIO({
                input_structure: CurrentProblem?.input_output_structure?.input_structure || [],
                output_structure: CurrentProblem?.input_output_structure?.output_structure || {},
                testcases: CurrentProblem?.testcases || [],
                hiddenTestCases: CurrentProblem?.hidden_testcases || {},
                problemLogicalData: CurrentProblem?.problem_logical_data || {},
                has_gamification: CurrentProblem?.has_gamification || false,
                correct_output: CurrentProblem?.gamification_data?.correct_output || '',
                correct_output_number: CurrentProblem?.gamification_data?.correct_output_number || 1,
                options: CurrentProblem?.gamification_data?.options || [],
            });
        }
    }, [CurrentProblem, resetIO]);

    const { isProd } = useSelector((state) => state.auth);
    const dispatch = useDispatch();
    const onSubmitIO = (data) => {
        try {
            setEncoderError(null);
            encoderFunction(data);

            if (encoderError) {
                throw new Error('Error in encoder function');
              }
            

            const problem_data = {
                type:"test_cases",
                id: CurrentProblem.id,
                slug: CurrentProblem.slug,
                input_output_structure: {
                    input_structure: data.input_structure,
                    output_structure: data.output_structure,
                },
                testcases: data.testcases,
                hidden_testcases: data.hiddenTestCases,
                problem_logical_data: data.problemLogicalData,
                has_gamification: data.has_gamification,
                gamification_data: {
                    correct_output: data.correct_output,
                    correct_output_number: data.correct_output_number,
                    options: data.options,
                },
            };

            dispatch(updateDSAProblem(problem_data, isProd));

        } catch (error) {
            //setEncoderError(error.message);
            toast.error(error.message, {
                style: {
                    borderRadius: '10px',
                    background: '#333',
                    color: '#fff',
                },
            });
        }
    };

    const handleLabelChange = (index, newLabel) => {
        const inputStructure = [...watchIO('input_structure')];
        inputStructure[index].label = newLabel;
        setValueIO('input_structure', inputStructure);

        // Update testcases inputs to reflect new labels
        const testcases = watchIO('testcases').map((testcase) => {
            const newInputs = {};
            inputStructure.forEach((input) => {
                newInputs[input.label] = testcase.inputs[input.label] || '';
            });
            return { ...testcase, inputs: newInputs };
        });
        setValueIO('testcases', testcases);
    };

    const removeLabel = (index) => {
        const inputStructure = watchIO('input_structure');
        const testcases = watchIO('testcases');
        const removedLabel = inputStructure[index].label;

        const updatedInputStructure = inputStructure.filter((_, i) => i !== index);
        setValueIO('input_structure', updatedInputStructure);

        const updatedTestcases = testcases.map((testcase) => {
            const newInputs = { ...testcase.inputs };
            delete newInputs[removedLabel];
            return { ...testcase, inputs: newInputs };
        });
        setValueIO('testcases', updatedTestcases);
    };

    const generateDriverCodeHandler = async (e) => {
        try {
        //TODO: Implement the function to generate driver code
        } catch (error) {
          toast.error(error.message || 'Error generating driver code');
        }
      };

    // const onEditorContentChanged = ({ name, html }) => {
    //     setValueIO(name, html);
    // };

    const addOptions = () => {
        const options = watchIO('options');
        setValueIO('options', [...options, '']);
    }

    const removeOptions = (index) => {
        const options = watchIO('options');
        setValueIO('options', options.filter((_, i) => i !== index));
    }

    return (
        <div className='flex flex-col gap-y-6'>
            <form id='inputOutputForm' onSubmit={handleSubmitIO(onSubmitIO)} className='space-y-6'>
                {/* <div className="p-6 space-y-6"> */}
                <Accordion title='Gamification'>
                    <div className='mb-10'>
                        <div className='flex items-center gap-x-4'>
                            <span className='text-gray-800 dark:text-zinc-300 text-[16px] font-medium'>
                                hasGamification <span className='text-red-500'>*</span>
                            </span>
                            {errorsIO.has_gamification && (
                                <span className='text-red-500'>
                                    Please enter at least 10 characters.
                                </span>
                            )}
                             <Controller
                                    name='has_gamification'
                                    control={controlIO}
                                    render={({ field, fieldState }) => (
                                        <div>
                                            <input
                                                type="checkbox"
                                                name='gamification_ouput'
                                                className='w-10 h-10 dark:text-blue-500'
                                                placeholder='Gamification'
                                                checked={field.value}
                                                {...field}
                                            />
                                            {fieldState?.error && (
                                                <span className='text-red-500'>
                                                    {fieldState.error.message}
                                                </span>
                                            )}
                                        </div>
                                    )}
                                />
                        </div>
                    </div>
                    {watchIO("has_gamification") && (
                        <>   
                            <span className='text-red-400'> <span className='font-bold'>Note:</span> Put Gamification Example from Content Section</span >
                            <div className='flex flex-col gap-y-2 mt-10'>
                                <span className='text-gray-800 dark:text-zinc-300 text-[16px] font-medium'>
                                    Output (Gamification)<span className='text-red-500'>*</span>
                                </span>
                                {errorsIO?.correct_output && (
                                    <span className='text-red-500'>
                                        {errorsIO?.correct_output.message}
                                    </span>
                                )}
                                <Controller
                                    name='correct_output'
                                    control={controlIO}
                                    render={({ field, fieldState }) => (
                                        <div>
                                            <input
                                                type='text'
                                                name='correct_output'
                                                className='form-input w-full text-gray-300'
                                                placeholder='Gamification Ouput'
                                                {...field}
                                            />
                                            {fieldState?.error && (
                                                <span className='text-red-500'>
                                                    {fieldState.error.message}
                                                </span>
                                            )}
                                        </div>
                                    )}
                                />
                            </div>
                            <div className='flex flex-col flex-wrap -mx-3 mb-4'>
                                    <span className='px-3 text-medium font-semibold text-light_30 mb-2 dark:text-zinc-300'>
                                        Correct Option
                                    </span>
                                    <div className='w-full px-3'>
                                        <Controller
                                            name='correct_output_number'
                                            control={controlIO}
                                            render={({ field }) => (
                                                <select
                                                    id='correct_output_number'
                                                    name='correct_output_number'
                                                    className='form-select w-full text-gray-300'
                                                    {...field}
                                                >
                                                    {watchIO("options")?.map((option, index) => (
                                                        <option key={index} value={index + 1}>
                                                            {index + 1}
                                                        </option>
                                                    ))}
                                                </select>
                                            )}
                                        />
                                        {errorsIO?.correct_output_number && (
                                            <p className='text-red-600 text-sm italic'>
                                                {errorsIO?.correct_output_number?.message}
                                            </p>
                                        )}
                                    </div>
                                </div>
                                {watchIO("options")?.map((option, index) => (
                                <div key={index} className='mt-4'>
                                    <div className='flex flex-col flex-wrap -mx-3 mb-4'>
                                        <div className='flex justify-between items-center'>
                                            <span className='mb-3 px-3 text-gray-800 dark:text-zinc-300 text-[16px] font-medium'>
                                                Option {index + 1}
                                            </span>
                                            {index === watchIO("options")?.length - 1 && (
                                                <button
                                                    type='button'
                                                    className='mr-4'
                                                    onClick={(e) => {
                                                        e.stopPropagation();
                                                        removeOptions(index);
                                                    }}
                                                >
                                                    <BadgeX className='stroke-red-600' size={18} />
                                                </button>
                                            )}
                                        </div>
                                        <div className='w-full px-3'>
                                            <Controller
                                                name={`options.${index}`}
                                                control={controlIO}
                                                defaultValue={option.value || ""} // Set the default value
                                                render={({ field }) => (
                                                    <input
                                                        id={`options.${index + 1}`}
                                                        type='text'
                                                        className='form-input w-full text-gray-300'
                                                        placeholder={`option ${index + 1}`}
                                                        {...field}
                                                    />
                                                )}
                                            />
                                        </div>
                                    </div>
                                </div>
                            ))}

                            {/* add plus btn to add options */}
                            <span>
                                <div className='mt-6'>
                                    <button
                                        type='button'
                                        onClick={addOptions}
                                        className='btn  bg-light_50 dark:bg-dark_50 w-full items-center justify-center flex'
                                    >
                                        <Plus size={18} className='stroke-zinc-600' />
                                    </button>
                                </div>
                            </span>

                        </>
                    )}
                    {/* {showExample3 && (
                        <>
                            

                            

                            <>
                                <div className='flex flex-col flex-wrap -mx-3 mb-4'>
                                    <span className='px-3 text-medium font-semibold text-light_30 mb-2 dark:text-zinc-300'>
                                        Correct Option
                                    </span>
                                    <div className='w-full px-3'>
                                        <Controller
                                            name='correctGamificationOptionNumber'
                                            control={control}
                                            render={({ field }) => (
                                                <select
                                                    id='correctGamificationOptionNumber'
                                                    name='correctGamificationOptionNumber'
                                                    className='form-select w-full text-gray-300'
                                                    {...field}
                                                >
                                                    {additionalOptions?.map((option, index) => (
                                                        <option key={index} value={index + 1}>
                                                            {index + 1}
                                                        </option>
                                                    ))}
                                                </select>
                                            )}
                                        />
                                        {isSubmitted && errors?.correctGamificationOptionNumber && (
                                            <p className='text-red-600 text-sm italic'>
                                                {errors?.correctGamificationOptionNumber?.message}
                                            </p>
                                        )}
                                    </div>
                                </div>
                            </>

                            {additionalOptions?.map((option, index) => (
                                <div key={index} className='mt-4'>
                                    <div className='flex flex-col flex-wrap -mx-3 mb-4'>
                                        <div className='flex justify-between items-center'>
                                            <span className='mb-3 px-3 text-gray-800 dark:text-zinc-300 text-[16px] font-medium'>
                                                Option {index + 1}
                                            </span>
                                            {index === additionalOptions.length - 1 && (
                                                <button
                                                    type='button'
                                                    className='mr-4'
                                                    onClick={(e) => {
                                                        e.stopPropagation();
                                                        removeOptions(option.id);
                                                    }}
                                                >
                                                    <BadgeX className='stroke-red-600' size={18} />
                                                </button>
                                            )}
                                        </div>
                                        <div className='w-full px-3'>
                                            <Controller
                                                name={`option${index + 1}`}
                                                control={control}
                                                defaultValue={option.value} // Set the default value
                                                render={({ field }) => (
                                                    <input
                                                        id={`option${index + 1}`}
                                                        type='text'
                                                        className='form-input w-full text-gray-300'
                                                        placeholder={`option ${index + 1}`}
                                                        {...field}
                                                    />
                                                )}
                                            />
                                        </div>
                                    </div>
                                </div>
                            ))}

                            <span>
                                <div className='mt-6'>
                                    <button
                                        type='button'
                                        onClick={addOptions}
                                        className='btn  bg-light_50 dark:bg-dark_50 w-full items-center justify-center flex'
                                    >
                                        <Plus size={18} className='stroke-zinc-600' />
                                    </button>
                                </div>
                            </span>
                        </> 
                    )} */}
                </Accordion>

                <Accordion title='Input and Output Generator'>
                    <div className='space-y-4'>
                        <h2 className='text-xl font-semibold text-slate-700 dark:text-zinc-300'>
                            Input and Output Generator
                        </h2>
                        <div>
                            <h3 className='text-lg font-medium text-slate-700 mb-2 dark:text-zinc-300 '>
                                Input
                            </h3>
                            <div className='space-y-3'>
                                {watchIO('input_structure')?.map((input, index) => (
                                    <div key={index} className='flex space-x-3 items-center'>
                                        <input
                                            placeholder='Label Name'
                                            onChange={(e) =>
                                                handleLabelChange(index, e.target.value)
                                            }
                                            className='flex-grow form-input p-2border text-slate-700border-gray-600 rounded focus:outline-none dark:text-zinc-300'
                                            type='text'
                                            required
                                            autoComplete='off'
                                            defaultValue={input.label || ''}
                                        />
                                        <select
                                            {...registerIO(`input_structure.${index}.type`)}
                                            className='flex-grow p-2 form-select text-gray-300 dark:text-zinc-300'
                                        >
                                            <option value='integer'>Integer</option>
                                            <option value='array'>Array</option>
                                            <option value='string'>String</option>
                                            <option value='boolean'>Boolean</option>
                                            <option value='char'>Char</option>
                                            <option value='float'>Float</option>
                                            <option value='2d-array'>2D Array</option>
                                            <option value='2d-array-dynamic'>
                                                2D Array Dynamic
                                            </option>
                                        </select>
                                        {watchIO(`input_structure.${index}.type`) === 'array' && (
                                            <select
                                                {...registerIO(`input_structure.${index}.subtype`)}
                                                className='flex-grow p-2 form-select text-gray-300 dark:text-zinc-300'
                                                defaultValue={'integer'}
                                                required
                                            >
                                                <option value='integer'>Integer</option>
                                                <option value='string'>String</option>
                                                <option value='boolean'>Boolean</option>
                                                <option value='char'>Char</option>
                                                <option value='float'>Float</option>
                                            </select>
                                        )}
                                        {watchIO(`input_structure.${index}.type`) ===
                                            '2d-array' && (
                                            <select
                                                {...registerIO(`input_structure.${index}.subtype`)}
                                                className='flex-grow p-2 form-select text-gray-300 dark:text-zinc-300'
                                                defaultValue={'integer'}
                                                required
                                            >
                                                <option value='integer'>Integer</option>
                                                <option value='string'>String</option>
                                                <option value='boolean'>Boolean</option>
                                                <option value='char'>Char</option>
                                                <option value='float'>Float</option>
                                            </select>
                                        )}
                                        {watchIO(`input_structure.${index}.type`) ===
                                            '2d-array-dynamic' && (
                                            <select
                                                {...registerIO(`input_structure.${index}.subtype`)}
                                                className='flex-grow p-2 form-select text-gray-300 dark:text-zinc-300'
                                                defaultValue={'integer'}
                                                required
                                            >
                                                <option value='integer'>Integer</option>
                                                <option value='string'>String</option>
                                                <option value='boolean'>Boolean</option>
                                                <option value='char'>Char</option>
                                                <option value='float'>Float</option>
                                            </select>
                                        )}
                                        <button
                                            type='button'
                                            onClick={() => removeLabel(index)}
                                            className='px-3 py-2 btn bg-red-600 text-slate-700 rounded hover:bg-red-700 transition duration-150 ease-in-out dark:text-zinc-300'
                                        >
                                            Delete Label
                                        </button>
                                    </div>
                                ))}
                            </div>
                            <button
                                type='button'
                                onClick={() => {
                                    setValueIO('input_structure', [
                                        ...watchIO('input_structure'),
                                        { label: 'nums', type: 'array', subtype: 'integer' },
                                    ]);
                                }}
                                className='mt-3 px-4 py-2 btn'
                            >
                                Add more label
                            </button>
                        </div>

                        <div>
                            <h3 className='text-lg font-medium text-slate-700 mb-2 dark:text-zinc-300'>
                                Output Type
                            </h3>
                            <select
                                {...registerIO('output_structure.type')}
                                className='w-full p-2 form-select text-gray-300'
                                defaultValue={watchIO('output_structure.type')}
                            >
                                <option value='integer'>Integer</option>
                                <option value='array'>Array</option>
                                <option value='string'>String</option>
                                <option value='boolean'>Boolean</option>
                            </select>
                        </div>
                    </div>
                    <h2 className='text-xl font-semibold text-slate-700 dark:text-zinc-300'>
                        Test Cases
                    </h2>
                    <Accordion title='Test Cases' className='mb-4'>
                        {watchIO('testcases')?.map((field, index) => (
                            <div key={field.id} className='space-y-3 p-4 rounded-lg'>
                                <h3 className='text-lg font-medium text-slate-700 dark:text-zinc-300 flex items-center gap-2'>
                                    Testcase {index + 1}
                                    <BadgeX
                                        size={15}
                                        className='text-red-500'
                                        onClick={() => {
                                            setValueIO(
                                                'testcases',
                                                watchIO('testcases').filter((v, i) => i !== index),
                                            );
                                        }}
                                    />
                                </h3>
                                {watchIO('input_structure').map((input, i) => (
                                    <div key={i} className='flex items-center space-x-3'>
                                        <label className='text-slate-700 w-1/4 dark:text-zinc-300'>
                                            {input.label}:
                                        </label>
                                        <input
                                            {...registerIO(
                                                `testcases.${index}.inputs.${input.label}`,
                                            )}
                                            placeholder='Input Value'
                                            className='flex-grow p-2 rounded text-slate-700 focus:outline-none dark:text-zinc-300'
                                            defaultValue={
                                                field.inputs ? field.inputs[input.label] : ''
                                            }
                                            type='text'
                                            required
                                            autoComplete='off'
                                        />
                                    </div>
                                ))}
                                <div className='flex items-center space-x-3'>
                                    <label className='text-slate-700 w-1/4 dark:text-zinc-300'>
                                        parsedInput:
                                    </label>
                                    <textarea
                                        rows={5}
                                        {...registerIO(`testcases.${index}.parsedInput`)}
                                        placeholder='Parsed Input Value'
                                        defaultValue={field.parsedInput}
                                        className='flex-grow p-2 rounded text-slate-700 focus:outline-none form-textarea dark:text-zinc-300'
                                        type='text'
                                        autoComplete='off'
                                    />
                                </div>
                                <div className='flex items-center space-x-3'>
                                    <label className='text-slate-700 w-1/4 dark:text-zinc-300'>
                                        parsedOutput:
                                    </label>
                                    <textarea
                                        rows={5}
                                        {...registerIO(`testcases.${index}.parsedOutput`)}
                                        placeholder='Output Value'
                                        defaultValue={field.parsedOutput}
                                        className='flex-grow p-2 rounded text-slate-700 focus:outline-none form-textarea dark:text-zinc-300'
                                        type='text'
                                        autoComplete='off'
                                    />
                                </div>
                            </div>
                        ))}
                        <button
                            onClick={() => {
                                if (watchIO('input_structure')?.length === 0) {
                                    toast.error('add input structure before adding test cases');
                                    return;
                                }
                                setValueIO('testcases', [...watchIO('testcases'), {}]);
                            }}
                            className='btn w-auto font-medium mx-2 '
                        >
                            Add new Test Case
                        </button>
                    </Accordion>
                    <button 
                        type="button" 
                        className="btn w-auto font-medium mx-2 mt-4" 
                        onClick={handleSubmitIO((data) => {
                            const encodedValue = encoderFunction(data);
                            setOutput(encodedValue);
                        })}
                        >
                        Run Encoder Function
                        </button>


                    <div className='p-6 space-y-6'>
                        <h2 className='text-xl font-semibold text-slate-700 dark:text-zinc-300'>
                            Output
                        </h2>
                        <div className='p-4 rounded-lg space-y-4'>
                            {!encoderError &&
                                output.map((out, index) => (
                                    <div
                                        key={index}
                                        className='border border-gray-600 p-3 rounded-lg space-y-2'
                                    >
                                        <div className='text-gray-300 '>
                                            <span className='font-semibold text-slate-700'>
                                                Test Case {index + 1} Encoded Input:
                                            </span>
                                        </div>
                                        <pre className='text-green-500 border border-gray-600 p-2 rounded-lg text-base'>
                                            {out.input}
                                        </pre>
                                    </div>
                                ))}
                            {encoderError && <div className='text-red-500'>{encoderError}</div>}
                        </div>
                    </div>
                </Accordion>
                {/* </div> */}
                {/* <div className="p-6"> */}
                <Accordion title='Problem Logical Data' className='mb-4'>
                    <div className='flex flex-col space-y-3 mb-4'>
                        <div className='space-y-1'>
                            <label className='text-slate-700 dark:text-zinc-300'>
                                CPP Time Limit
                            </label>
                            <input
                                {...registerIO('problemLogicalData.cppTimeLimit')}
                                placeholder='CPP Time Limit'
                                className='form-input w-full text-gray-300'
                                defaultValue={2}
                                type='number'
                            />
                        </div>
                    </div>

                    <div className='flex flex-col space-y-3 mb-4'>
                        <div className='space-y-1'>
                            <label className='text-slate-700 dark:text-zinc-300'>
                                Testcase Generator Code
                            </label>
                            <textarea
                                {...registerIO('problemLogicalData.testCaseGeneratorCode')}
                                placeholder='TestCase Code Generator'
                                className='form-input w-full text-gray-300'
                                defaultValue={''}
                                type='text'
                                rows='5'
                            />
                        </div>
                    </div>

                    {problemLogicalData && (
                        <div className='space-y-4 mb-4'>
                            {['cpp', 'java', 'python', 'javascript'].map((lang, index) => (
                                <Accordion
                                    key={index}
                                    title={`${lang.charAt(0).toUpperCase() + lang.slice(1)} Logical Data`}
                                    className='my-4'
                                >
                                    <h3 className='font-semibold text-slate-700 dark:text-zinc-300'>
                                        {lang.toUpperCase()}
                                    </h3>

                                    <div className='flex flex-col space-y-3'>
                                        <div className='space-y-1'>
                                            <label className='text-slate-700 dark:text-zinc-300'>
                                                Header Code
                                            </label>
                                            <textarea
                                                {...registerIO(
                                                    `problemLogicalData.${lang}.headerCode`,
                                                )}
                                                placeholder='Header Code'
                                                className='form-input w-full text-gray-300'
                                                defaultValue={''}
                                                rows='5'
                                            />
                                        </div>

                                        <div className='space-y-1'>
                                            <label className='text-slate-700 dark:text-zinc-300'>
                                                Show Code on IDE
                                            </label>
                                            <textarea
                                                {...registerIO(
                                                    `problemLogicalData.${lang}.showCodeOnIDE`,
                                                )}
                                                placeholder='Show On IDE'
                                                className='form-input w-full text-gray-300'
                                                defaultValue={''}
                                                rows='5'
                                            />
                                        </div>

                                        <div className='space-y-1'>
                                            <label className='text-slate-700 dark:text-zinc-300'>
                                                Driver Code
                                            </label>
                                            <textarea
                                                {...registerIO(
                                                    `problemLogicalData.${lang}.driverCode`,
                                                )}
                                                placeholder='Driver Code'
                                                className='form-input w-full text-gray-300'
                                                defaultValue={''}
                                                rows='5'
                                            />
                                        </div>
                                    </div>
                                </Accordion>
                            ))}
                        </div>
                    )}
                    <Accordion title='Correct Code and Language' className='mb-4'>
                        <div className='flex flex-col space-y-3'>
                            <div className='space-y-1'>
                                <label className='text-slate-700 dark:text-zinc-300'>
                                    Correct Code Language
                                </label>
                                <select
                                    {...registerIO(
                                        'problemLogicalData.correctCodeAndLanguage.correctCodeLanguage',
                                    )}
                                    className='form-select w-full p-2 rounded border border-gray-300 focus:outline-none focus:ring-2 focus:ring-blue-500 text-slate-700 dark:text-zinc-300'
                                    defaultValue={'cpp'}
                                >
                                    <option value='cpp'>C++</option>
                                    <option value='java'>Java</option>
                                    <option value='python'>Python</option>
                                    <option value='javascript'>JavaScript</option>
                                </select>
                            </div>

                            <div className='space-y-1'>
                                <label className='text-slate-700 dark:text-zinc-300'>
                                    Correct Code
                                </label>
                                <textarea
                                    {...registerIO(
                                        'problemLogicalData.correctCodeAndLanguage.correctCode',
                                    )}
                                    placeholder='Enter the correct code here'
                                    className='form-textarea w-full p-2 rounded border border-gray-300 focus:outline-none focus:ring-2 focus:ring-blue-500 text-slate-700 dark:text-zinc-300'
                                    defaultValue={''}
                                    rows='6'
                                />
                            </div>
                        </div>
                    </Accordion>
                    <button
                        onClick={generateDriverCodeHandler}
                        type='button'
                        className='btn w-auto font-medium mx-2 mt-4 bg-green-600'
                    >
                        Generate Driver Code with AI
                    </button>
                </Accordion>
                {/* </div> */}
                {/* LargeTestFiles */}
                {/* <div className="p-6 space-y-6"> */}
                <Accordion title='Hidden TestCases' className='mb-4'>
                    {problemLogicalData && (
                        <div className='space-y-4'>
                            {[
                                'largeInputFileWA',
                                'largeOutputFileWA',
                                'largeInputFileTLE',
                                'largeOutputFileTLE',
                            ].map((file, index) => (
                                <div key={index} className='flex flex-col space-y-3'>
                                    <div className='space-y-1'>
                                        <label className='text-slate-700 dark:text-zinc-300'>
                                            {file
                                                .replace(/([A-Z](?=[^TLE|^WA]))/g, ' $1') // Add spaces before uppercase letters, except for WA and TLE
                                                .replace(/^./, (str) => str.toUpperCase())}{' '}
                                        </label>
                                        <input
                                            {...registerIO(`hiddenTestCases.${file}`)}
                                            placeholder={file
                                                .replace(/([A-Z](?=[^TLE|^WA]))/g, ' $1') // Same label formatting for placeholder
                                                .replace(/^./, (str) => str.toUpperCase())}
                                            className={`form-input w-full p-2 rounded border border-gray-300 focus:outline-none focus:ring-2 focus:ring-blue-500 text-slate-700 dark:text-zinc-300 ${
                                                errorsIO.hiddenTestCases?.[file]
                                                    ? 'border-red-500'
                                                    : ''
                                            }`}
                                            defaultValue={''}
                                        />
                                    </div>
                                </div>
                            ))}
                        </div>
                    )}
                </Accordion>
                <button type='submit' className='btn w-auto font-medium mx-2 '>
                    Save Section 3
                </button>
            </form>
        </div>
    );
};

export default ContentEdit;
