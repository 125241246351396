/* eslint-disable no-unused-vars */
import React, { useState, useEffect, useRef } from 'react';
import Transition from '../../utils/Transition';
import toast from 'react-hot-toast';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import { htmlToMarkdown, markdownToHtml } from '../RichEditor/Parser';
import { useSelector, useDispatch } from 'react-redux';
import {
  setUserDetails,
  updateCallStatusRedux,
  setEmail,
  setCodeUsedRedux,
} from '../../slices/callsSlice';
import moment from 'moment';
import { UploadIcon, DownloadIcon, FullscreenIcon } from 'lucide-react';
import {
  fetchFailedUserDetails,
  updateCallStatus,
  addCouponCode,
} from '../../services/operations/calls';
import useUpdateCallDetails from '../../hooks/useUpdateCallDetails';
import TranscriptModal from './TranscriptModal';

function FailedEntryDetailsModal({ email, modalOpen, setModalOpen }) {
  const ALLOWED_EXTENSIONS = ['.txt', '.json'];

  const modalContent = useRef(null);
  const userEntryDetails = useSelector((state) => state.calls.userDetails);
  const userCallDetails = useSelector((state) => state.calls.callDetails?.[0]);
  const userType = useSelector((state) => state.calls.userType);
  const [transcriptModalOpen, setTranscriptModalOpen] = useState(false);

  const dispatch = useDispatch();

  useEffect(() => {
    const clickHandler = ({ target }) => {
      if (!modalOpen || transcriptModalOpen) return; // Don't close if TranscriptModal is open
      if (modalContent.current.contains(target)) return;

      // Close outer modal logic
      setModalOpen(false);
      dispatch(updateCallStatusRedux(''));
      dispatch(setEmail(''));
      dispatch(setCodeUsedRedux(''));
      dispatch(setUserDetails({}));
    };

    document.addEventListener('mousedown', clickHandler);
    return () => document.removeEventListener('mousedown', clickHandler);
  }, [dispatch, modalOpen, setModalOpen, transcriptModalOpen]);

  useEffect(() => {
    const keyHandler = ({ keyCode }) => {
      if (!modalOpen || keyCode !== 27) return;
      if (transcriptModalOpen) return; // Don't close outer modal if TranscriptModal is open

      // Close outer modal logic
      setModalOpen(false);
      dispatch(updateCallStatusRedux(''));
      dispatch(setEmail(''));
      dispatch(setCodeUsedRedux(''));
      dispatch(setUserDetails({}));
    };

    document.addEventListener('keydown', keyHandler);
    return () => document.removeEventListener('keydown', keyHandler);
  }, [dispatch, modalOpen, setModalOpen, transcriptModalOpen]);

  const [isEditingCodeUsed, setIsEditingCodeUsed] = useState(false);
  const code_used = useSelector((state) => state.calls.code_used || 'NONE');
  const [codeUsed, setCodeUsed] = useState(code_used);

  const call_status = useSelector((state) => state.calls.status || 'not called');
  const [transcript, setTranscript] = useState('');
  const [callStatus, setCallStatus] = useState(userEntryDetails?.call_status || 'not called');

  useEffect(() => {
    setCodeUsed(code_used);
  }, [code_used]);
  // Notes Field states
  const [isEditing, setIsEditing] = useState(false);
  const [notes, setNotes] = useState(markdownToHtml());
  const reactQuillRef = useRef(null);

  // For Transcript field
  const [isUploadActive, setIsUploadActive] = useState(false);

  // Duration Field States
  const [duration, setDuration] = useState();
  const [durationInput, setDurationInput] = useState();
  const [isDurationEditable, setIsDurationEditable] = useState(false);
  const fileInputRef = useRef(null);

  useEffect(() => {
    if (modalOpen && email) {
      dispatch(setEmail(email));
      if (userType === 'failed') {
        dispatch(fetchFailedUserDetails(email));
      }
    }
  }, [modalOpen, email, userType, dispatch]);

  useEffect(() => {
    setDuration(userCallDetails?.duration);
    setTranscript(userCallDetails?.transcript);
    setNotes(userCallDetails?.notes);
  }, [userCallDetails]);

  useEffect(() => {
    dispatch(updateCallStatusRedux(userEntryDetails?.call_status));
    dispatch(setCodeUsedRedux(userEntryDetails?.code_used));
  }, [dispatch, userEntryDetails]);

  const handleStatusUpdate = (selectedValue) => {
    setCallStatus(selectedValue);
    dispatch(updateCallStatus(email, userType, selectedValue));
    dispatch(updateCallStatusRedux(selectedValue));
  };

  const handleCodeUsedChange = (newCode) => {
    const trimmedCode = newCode.trim();
    if (trimmedCode !== code_used) {
      dispatch(addCouponCode(trimmedCode.toUpperCase(), email, userType));
      dispatch(setCodeUsedRedux(trimmedCode.toUpperCase()));
    }
    setCodeUsed(trimmedCode.toUpperCase());
  };

  const updateCallDetails = useUpdateCallDetails();

  const isValidDuration = (duration) => /^\d{1,2}:\d{1,2}$/.test(duration);

  const formatDuration = (duration) => {
    if (isValidDuration(duration)) {
      const [minutes, seconds] = duration.split(':');
      return `${minutes.padStart(2, '0')} mins ${seconds.padStart(2, '0')} secs`;
    }
    return '00 mins 00 secs';
  };

  const handleOpenTranscriptModal = () => {
    setTranscriptModalOpen(true);
  };

  const handleCloseTranscriptModal = () => {
    setTranscriptModalOpen(false);
  };

  const toggleDurationEdit = () => {
    if (!isDurationEditable) {
      setDurationInput(duration);
    }
    setIsDurationEditable((prev) => !prev);
  };

  const handleDurationSave = () => {
    if (!isValidDuration(durationInput)) {
      toast.error('Invalid format. Please use MM:SS.');
      setIsDurationEditable(false);
      return;
    }
    setDuration((prev) => durationInput);
    toggleDurationEdit();
    updateCallDetails({
      transcript: transcript,
      duration: durationInput,
      notes: notes,
      email: email,
      userType: userType,
    });
  };

  const handlePlaceholderClick = () => {
    fileInputRef.current.click();
  };

  const handleFileUpload = async (file) => {
    if (!file) return;

    try {
      const reader = new FileReader();
      reader.onload = async (event) => {
        const fileContent = event.target.result;

        const extractedText = fileContent.toString();

        setTranscript((prev) => extractedText);

        updateCallDetails({
          transcript: extractedText,
          duration: duration,
          notes: notes,
          email: email,
          userType: userType,
        });

        toast.success('Transcript uploaded successfully!');
        setIsUploadActive(false);
      };

      if (file.type === 'text/plain') {
        reader.readAsText(file);
      } else {
        toast.error('Unsupported file type. Please upload a text file.');
      }
    } catch (error) {
      toast.error('Failed to upload transcript. Please try again.');
    }
  };

  const handleFileDrop = (e) => {
    e.preventDefault();
    if (e.dataTransfer.files.length) {
      handleFileUpload(e.dataTransfer.files[0]);
    }
  };

  const downloadTranscript = () => {
    const transcriptContent = transcript || '' || 'No transcript available.';
    const blob = new Blob([transcriptContent], { type: 'text/plain' });
    const link = document.createElement('a');
    link.href = URL.createObjectURL(blob);
    link.download = 'transcript.txt';
    link.click();
  };

  // Copy text to clipboard with success/error notifications
  const copyToClipboard = (text) => {
    navigator.clipboard
      .writeText(text || '')
      .then(() => toast.success('Copied to clipboard!'))
      .catch(() => toast.error('Failed to copy.'));
  };

  const handleEdit = () => {
    if (isEditing) {
      const editorContent = reactQuillRef.current?.getEditor().root.innerHTML || '';
      setNotes(editorContent.trim() || '');
    }
    setIsEditing((prev) => !prev);
  };

  const handleSaveNotes = () => {
    let editorContent;
    if (reactQuillRef.current) {
      editorContent = reactQuillRef.current.getEditor().root.innerHTML.trim();
      setNotes((prev) => editorContent || '');
    }

    updateCallDetails({
      transcript: transcript,
      duration: duration,
      notes: notes,
      email: email,
      userType: userType,
    });

    setIsEditing(false);
  };

  // Close modal and clear user details
  const handleClose = (e) => {
    e.stopPropagation();
    dispatch(updateCallStatusRedux(''));
    dispatch(setEmail(''));
    dispatch(setCodeUsedRedux(''));
    dispatch(setUserDetails({}));
    setModalOpen(false);
  };

  return (
    <>
      <Transition
        className='fixed inset-0 bg-gray-900 bg-opacity-30 z-50 transition-opacity'
        show={modalOpen}
        enter='transition ease-out duration-200'
        enterStart='opacity-0'
        enterEnd='opacity-100'
        leave='transition ease-out duration-100'
        leaveStart='opacity-100'
        leaveEnd='opacity-0'
        aria-hidden='true'
      />
      <Transition
        className='fixed inset-0 z-50 flex items-center justify-center px-4 sm:px-6'
        show={modalOpen}
        enter='transition ease-in-out duration-200'
        enterStart='opacity-0 translate-y-4'
        enterEnd='opacity-100 translate-y-0'
        leave='transition ease-in-out duration-200'
        leaveStart='opacity-100 translate-y-0'
        leaveEnd='opacity-0 translate-y-4'
      >
        {/* <div className='fixed inset-0 z-50 flex items-center justify-center px-4 sm:px-6'> */}
        <div
          ref={modalContent}
          className='bg-white dark:bg-dark_50 rounded-lg shadow-lg overflow-y-auto no-scrollbar w-[80%] max-h-[80%] flex flex-col  p-4 sm:p-6 '
        >
          {/* Modal Header */}
          <div className='flex justify-between items-center border-b dark:border-zinc-700'>
            <h2 className='font-bold text-xl text-zinc-800 dark:text-zinc-100'>User Details</h2>
            <button
              className='text-sm font-medium text-red-500 hover:text-red-700'
              onClick={handleClose}
            >
              Close
            </button>
          </div>

          {/* Two-column layout */}
          <div className='grid grid-cols-1 sm:grid-cols-2 gap-6'>
            {/* Left Column */}
            <div className='space-y-4'>
              {/* Transaction Details */}
              <div className='space-y-4'>
                <div>
                  <h3 className='text-lg font-semibold'>Transaction Details</h3>
                  <div className='grid grid-cols-1 sm:grid-cols-2 gap-x-10 gap-y-4'>
                    {/* Left Column */}
                    <div className='space-y-3 text-xs sm:text-sm'>
                      {[
                        {
                          label: 'Transaction ID',
                          value: userEntryDetails?.transaction_details?.id || 'N/A',
                        },
                        {
                          label: 'Transaction Type',
                          value: userEntryDetails?.transaction_details?.international
                            ? 'International'
                            : userEntryDetails?.transaction_details?.international === false
                              ? 'Domestic'
                              : 'N/A',
                        },
                        {
                          label: 'Amount',
                          value: `${userEntryDetails?.transaction_details?.totalAmount || 'N/A'} ${
                            userEntryDetails?.transaction_details?.currency || ''
                          }`,
                        },
                      ].map(({ label, value, copy }, index) => (
                        <div key={index} className='flex flex-row gap-x-2'>
                          <div className='flex items-center'>
                            <label className='font-semibold'>{label}:</label>
                            {copy && (
                              <button
                                className='ml-2 text-[10px] sm:text-xs text-red-500 font-medium hover:text-red-700'
                                onClick={() => copyToClipboard(value)}
                              >
                                Copy
                              </button>
                            )}
                          </div>
                          <span className='break-all'>{value}</span>
                        </div>
                      ))}

                      {/* Code Used Field */}
                      <div className='flex flex-row gap-x-2'>
                        <div className='flex items-center'>
                          <label className='font-semibold mr-2'>Code Used:</label>
                          {isEditingCodeUsed ? (
                            <input
                              type='text'
                              value={codeUsed}
                              onChange={(e) => setCodeUsed(e.target.value)}
                              onKeyDown={(e) => {
                                if (e.key === 'Enter') {
                                  handleCodeUsedChange(codeUsed);
                                  setCodeUsed((prev) => prev.toUpperCase());
                                  setIsEditingCodeUsed(false);
                                }
                              }}
                              className='px-2 py-1 border rounded-md focus:ring focus:ring-red-500 focus:outline-none'
                            />
                          ) : (
                            <span>{code_used || 'None'}</span>
                          )}
                          <button
                            className='ml-2 text-[10px] sm:text-xs text-red-500 font-medium hover:text-red-700'
                            onClick={() => {
                              if (isEditingCodeUsed) {
                                handleCodeUsedChange(codeUsed);
                                setCodeUsed((prev) => prev.toUpperCase());
                              }
                              setIsEditingCodeUsed(!isEditingCodeUsed);
                            }}
                          >
                            {isEditingCodeUsed ? 'Save' : 'Edit'}
                          </button>
                        </div>
                      </div>
                    </div>

                    {/* Right Column */}
                    <div className='space-y-3 text-xs sm:text-sm'>
                      {[
                        {
                          label: 'Batch',
                          value: userEntryDetails?.transaction_details?.batch || 'N/A',
                        },
                        {
                          label: 'Plan Name',
                          value: userEntryDetails?.transaction_details?.plan_name || 'N/A',
                        },
                        {
                          label: 'Name',
                          value: userEntryDetails?.transaction_details?.name || 'N/A',
                        },
                        {
                          label: 'Location',
                          value: userEntryDetails?.transaction_details?.location || 'N/A',
                        },
                        {
                          label: 'Created',
                          value:
                            moment(userEntryDetails?.created_at).format('MMM DD, YYYY, h:mm A') ||
                            'N/A',
                        },
                      ].map(({ label, value }, index) => (
                        <div key={index} className='flex flex-row gap-x-2'>
                          <label className='font-semibold'>{label}:</label>
                          <span>{value}</span>
                        </div>
                      ))}
                    </div>
                  </div>
                </div>
              </div>

              {/* Divider */}
              <hr className='my-4 border-t border-gray-300 dark:border-zinc-700' />

              <div>
                <h3 className='text-lg font-semibold'>Customer Information</h3>
                <div className='grid grid-cols-1 sm:grid-cols-2 gap-x-10 gap-y-4'>
                  {/* Left Column */}
                  <div className='space-y-3 text-xs sm:text-sm'>
                    {/* Email */}
                    <div className='flex flex-row gap-x-2'>
                      <div className='flex items-center'>
                        <label className='font-semibold mr-2'>Email:</label>
                        <span className='break-all'>{userEntryDetails?.email || 'N/A'}</span>
                        {userEntryDetails?.email && (
                          <button
                            className='ml-2 text-[10px] sm:text-xs text-red-500 font-medium hover:text-red-700'
                            onClick={() => copyToClipboard(userEntryDetails.email)}
                          >
                            Copy
                          </button>
                        )}
                      </div>
                    </div>

                    {/* Contact */}
                    <div className='flex flex-row gap-x-2'>
                      <div className='flex items-center'>
                        <label className='font-semibold mr-2'>Contact:</label>
                        <span className='break-all'>{userEntryDetails?.contact || 'N/A'}</span>
                        {userEntryDetails?.contact && (
                          <button
                            className='ml-2 text-[10px] sm:text-xs text-red-500 font-medium hover:text-red-700'
                            onClick={() => copyToClipboard(userEntryDetails.contact)}
                          >
                            Copy
                          </button>
                        )}
                      </div>
                    </div>
                  </div>

                  {/* Right Column */}
                  <div className='space-y-3 text-xs sm:text-sm'>
                    {/* Enrollment Status */}
                    <div className='flex flex-row gap-x-2'>
                      <label className='font-semibold'>Enrollment Status:</label>
                      <span
                        className={
                          userEntryDetails?.isEnrolled
                            ? 'text-green-500 font-medium'
                            : 'text-red-500 font-medium'
                        }
                      >
                        {userEntryDetails?.isEnrolled ? 'Enrolled' : 'Not Enrolled'}
                      </span>
                    </div>

                    <div className='flex flex-row gap-x-2 text-xs sm:text-sm items-center'>
                      <label className='font-semibold mb-1'>Call Status:</label>
                      <select
                        className='text-xs sm:text-sm px-2 py-1 bg-gray-50 dark:bg-zinc-800 rounded border border-gray-200 dark:border-zinc-700 focus:outline-none focus:ring focus:ring-red-500 appearance-none'
                        value={call_status}
                        onChange={(e) => handleStatusUpdate(e.target.value)} // Call the function with selected value
                      >
                        <option value='not called'>Not Called</option>
                        <option value='wrong num'>Wrong Number</option>
                        <option value='unreachable'>Unreachable</option>
                        <option value='bought on call'>Bought on Call</option>
                        <option value='call later'>Call Later</option>
                        <option value='busy'>Busy</option>
                      </select>
                    </div>
                  </div>
                </div>
              </div>

              {/* Divider */}
              <hr className='my-4 border-t border-gray-300 dark:border-zinc-700' />

              {/* Error Summary */}
              {userEntryDetails?.error_details && (
                <div className='bg-gray-100 dark:bg-zinc-800 p-4 rounded shadow-md'>
                  <div className='grid grid-cols-1 gap-4 md:gap-6 text-xs sm:text-sm'>
                    {/* First Row: Error Code, Error Step, and Reason for Failure */}
                    <div className='grid grid-cols-1 sm:grid-cols-3 gap-4'>
                      {/* Error Code */}
                      <div className='flex flex-col'>
                        <span className='font-semibold'>Error Code:</span>
                        <span className='mt-1 break-all'>
                          {userEntryDetails?.error_details?.error_code || 'N/A'}
                        </span>
                      </div>

                      {/* Error Step */}
                      <div className='flex flex-col'>
                        <span className='font-semibold'>Error Step:</span>
                        <span className='mt-1 break-all'>
                          {userEntryDetails?.error_details?.error_step || 'N/A'}
                        </span>
                      </div>

                      {/* Reason for Failure */}
                      <div className='flex flex-col'>
                        <span className='font-semibold'>Reason for Failure:</span>
                        <span className='mt-1 break-all'>
                          {userEntryDetails?.error_details?.error_reason || 'N/A'}
                        </span>
                      </div>
                    </div>

                    {/* Second Row: Description */}
                    <div className='flex flex-col'>
                      <span className='font-semibold'>Description:</span>
                      <span className='mt-1 break-words'>
                        {userEntryDetails?.error_details?.error_description || 'N/A'}
                      </span>
                    </div>
                  </div>
                </div>
              )}
            </div>

            {/* Right Column */}
            <div>
              {/* Call Details */}
              <div className='space-y-4 mt-2'>
                <h3 className='text-lg font-semibold'>Call Details</h3>

                <div className='space-y-6 text-sm sm:text-base md:text-lg'>
                  {/* Transcript Section */}
                  <div>
                    <div className='flex justify-between items-center mb-2'>
                      <label className='font-semibold text-base'>Transcript:</label>
                      <div className='flex items-center gap-4'>
                        {/* View Full Transcript */}
                        {transcript && !isUploadActive && (
                          <button
                            className='text-lg sm:text-xl text-red-500 opacity-50 hover:opacity-100 transition-opacity'
                            title='View Full Transcript'
                            onClick={handleOpenTranscriptModal}
                          >
                            <FullscreenIcon />
                          </button>
                        )}

                        {/* Download Transcript */}
                        {transcript && !isUploadActive && (
                          <button
                            className='text-lg sm:text-xl text-red-500 opacity-50 hover:opacity-100 transition-opacity'
                            title='Download Transcript'
                            onClick={downloadTranscript}
                          >
                            <DownloadIcon />
                          </button>
                        )}

                        {/* Upload Transcript */}
                        <button
                          className={`text-lg sm:text-xl ${
                            isUploadActive ? 'text-red-500 opacity-100' : 'text-red-500 opacity-50'
                          } hover:opacity-100 transition-opacity`}
                          title='Upload Transcript'
                          onClick={() => setIsUploadActive((prev) => !prev)}
                        >
                          <UploadIcon />
                        </button>
                      </div>
                    </div>

                    {/* Drag and Drop Placeholder */}
                    {isUploadActive ? (
                      <div
                        className='mt-4 p-4 border-dashed border-2 border-gray-300 rounded bg-gray-50 dark:bg-zinc-800 flex items-center justify-center cursor-pointer'
                        onClick={handlePlaceholderClick}
                        onDrop={handleFileDrop}
                        onDragOver={(e) => e.preventDefault()}
                      >
                        <p className='text-sm sm:text-base text-gray-800 dark:text-gray-300'>
                          Drag and drop a file here, or click to upload.
                        </p>
                        <input
                          type='file'
                          ref={fileInputRef}
                          className='hidden'
                          accept={ALLOWED_EXTENSIONS.join(',')}
                          onChange={(e) => handleFileUpload(e.target.files[0])}
                        />
                      </div>
                    ) : (
                      <div className='relative p-4 bg-gray-50 dark:bg-zinc-800 rounded shadow-md max-h-40 overflow-y-auto border border-gray-200 dark:border-zinc-700'>
                        {transcript ? (
                          <p className='whitespace-pre-wrap text-sm sm:text-base text-gray-800 dark:text-gray-300 mb-2'>
                            {transcript}
                          </p>
                        ) : (
                          <p className='whitespace-pre-wrap text-sm sm:text-base text-gray-800 dark:text-gray-300'>
                            Transcript not available.
                          </p>
                        )}
                      </div>
                    )}
                  </div>

                  {/* Duration Field */}
                  <div className='flex flex-col'>
                    <div className='flex items-center space-x-2'>
                      <span className='font-semibold  text-base'>Duration:</span>
                      {isDurationEditable ? (
                        <input
                          type='text'
                          value={durationInput}
                          onChange={(e) => setDurationInput(e.target.value)}
                          className='w-32 px-2 py-1 border rounded-md text-sm sm:text-base focus:ring focus:ring-red-500 focus:outline-none dark:bg-gray-800 dark:border-zinc-700'
                          placeholder='MM:SS'
                        />
                      ) : (
                        <span className='px-2 py-1 border rounded-md bg-gray-50 text-sm sm:text-base text-gray-800 dark:bg-zinc-800 dark:text-gray-300 dark:border-zinc-700'>
                          {formatDuration(duration)}
                        </span>
                      )}
                      <button
                        className='ml-2 text-[10px] sm:text-xs text-red-500 font-medium hover:text-red-700'
                        onClick={isDurationEditable ? handleDurationSave : toggleDurationEdit}
                      >
                        {isDurationEditable ? 'Save' : 'Edit'}
                      </button>
                    </div>
                  </div>

                  {/* Notes Field */}
                  <div className='flex flex-col'>
                    <div className='flex items-center justify-between mb-2'>
                      <span className='font-semibold  text-base'>Notes:</span>
                      <button
                        className={`px-3 py-1 rounded text-sm font-medium ${
                          isEditing
                            ? 'bg-green-500 text-white hover:bg-green-600'
                            : 'bg-red-500 text-white hover:bg-red-600'
                        }`}
                        onClick={isEditing ? handleSaveNotes : handleEdit}
                      >
                        {isEditing ? 'Save' : 'Edit'}
                      </button>
                    </div>
                    {isEditing ? (
                      <ReactQuill
                        ref={reactQuillRef}
                        theme='snow'
                        placeholder='Start writing...'
                        value={notes ? notes : ''}
                        className='rich-editor-height rounded h-100'
                        onChange={setNotes}
                      />
                    ) : (
                      <div
                        className='p-3 bg-gray-50 dark:bg-zinc-800 rounded shadow-md border border-gray-200 dark:border-zinc-700'
                        dangerouslySetInnerHTML={{
                          __html: notes || 'No notes available.',
                        }}
                      />
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* </div> */}
      </Transition>

      <TranscriptModal
        isOpen={transcriptModalOpen}
        onClose={handleCloseTranscriptModal}
        transcript={transcript}
        downloadTranscript={downloadTranscript}
      />
    </>
  );
}

export default FailedEntryDetailsModal;
