import React, { useEffect, useState } from 'react'
import Header from '../../components/Header/Header'
import Sidebar from '../../components/Sidebar/Sidebar'
import { useDispatch, useSelector } from 'react-redux';
import { Controller, useForm } from 'react-hook-form';
import UsersModal from '../../components/Shared/Modals/UsersModal';
import UsersTable from '../../components/Table/UsersTable/UsersTable';
import { getAllUsers, addUser, updateUser, removePremiumAccess } from '../../services/operations/grantAccess';
import { getAllPlans } from '../../services/operations/premium';
import { setUser } from '../../slices/grantAccessSlice';

const GrantAccess = () => {
    
    const [sidebarOpen, setSidebarOpen] = useState(false);
    const [modalOpen, setModalOpen] = useState(false);
    const [isEdit, setIsEdit] = useState(false);
    const [modalDeleteOpen, setModalDeleteOpen] = useState(false);

    const dispatch = useDispatch()

    const { handleSubmit: handleRemoveSubmit, control: removeControl, formState: { errors: removeErrors, isSubmitted: isRemoveSubmitted }, reset: resetRemove } = useForm({
        defaultValues: {
            removeEmail: '', 
        }
    });

    const { handleSubmit: handleUserSubmit, control: userControl, formState: { errors: userErrors, isSubmitted: isUserSubmitted }, reset: resetUser } = useForm({
        defaultValues: {
            email: '', 
            name: '',
            username: '',
            isPremium: '1',
            plan_name: '',
            validity: '',
            medium: 'privileged',
        }
    });

    const { user } = useSelector((state) => state.grantAccess)
    
    const { allPlans } = useSelector((state) => state.premium)
    const { isProd, allUsers } = useSelector((state) => state.auth)

    useEffect(() => {
        dispatch(getAllPlans())
    }, [dispatch]);

    useEffect(() => {
        dispatch(getAllUsers())
    }, [dispatch])
    console.log("allUsers: ", allUsers);
    
    useEffect(() => {
        dispatch(setUser({}))
    }, [dispatch]);

    useEffect(() => {
        resetUser(user)
    }, [resetUser, user]);

    const isEmailValid = (value) => {
        const emailPattern = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i;
        return emailPattern.test(value) || 'Invalid email address';
    };

    const handleModalOpen = (e) => {
        e.stopPropagation();
        resetUser()
        const data = {
            email: '',
            name: '', 
            username: '',
            isPremium: '1', 
            plan_name: '', 
            validity: '', 
            medium: 'privileged',
        }
        
        dispatch(setUser(data))
        setIsEdit(false)
        setModalOpen(true);
    }
    
    const handleOnSubmit = async (data) => {

        if (isEdit) {
            const { email, name, isPremium, plan_name, validity, medium } = data;
            dispatch(updateUser(email, name, isPremium, plan_name, validity, medium, setModalOpen));
        }
        else {
            const { email, name, username, isPremium, plan_name, validity, medium } = data;
            dispatch(addUser(email, name, username, isPremium, plan_name, validity, medium, setModalOpen, isProd));
        }
    };

    const handleRemovePremiumAccessModal = (e) => {
        e.stopPropagation()
        resetRemove()

        setModalDeleteOpen(true);
    }
    const handleRemovePremiumAccess = async (data) => {
        console.log('Form data:', data);
        const { removeEmail } = data;
        console.log('Removing premium access for email:', removeEmail); 
        dispatch(removePremiumAccess(removeEmail, setModalDeleteOpen));
    }

    return (
        <div className="flex dark:bg-dark  h-screen overflow-hidden">
            <Sidebar sidebarOpen={sidebarOpen} setSidebarOpen={setSidebarOpen} />
            <div className="relative flex flex-col flex-1 overflow-y-auto overflow-x-hidden">
                <Header sidebarOpen={sidebarOpen} setSidebarOpen={setSidebarOpen} />
                <main>
                    <div className="px-4 sm:px-6 lg:px-8 py-8 w-full max-w-9xl mx-auto">
                        <div className='flex mt-4 mb-8 flex-row justify-between'>
                            <button onClick={(e) => handleModalOpen(e)} className='btn-brand-2'>Add User</button>
                            <button onClick={(e) => handleRemovePremiumAccessModal(e)} className='btn-brand-2'>Remove Premium Access</button>
                        </div>
                        {allUsers && allUsers.length > 0 ? (
                            <>
                                <UsersTable 
                                    users={allUsers}
                                    setIsEdit={setIsEdit}
                                    setModalOpen={setModalOpen}
                                    modalOpen={modalOpen} 
                                />
                            </>
                        ) : (
                            <div className='flex justify-center items-center text-4xl dark:text-zinc-300'>
                                No privileged users
                            </div>
                        )}
                        <UsersModal title={"Delete User"} modalOpen={modalDeleteOpen} setModalOpen={setModalDeleteOpen}>
                            <form onSubmit={handleRemoveSubmit(handleRemovePremiumAccess)} className='mt-6'>
                                <div className="flex flex-wrap -mx-3 mb-4">
                                <span className='px-3 text-lg font-semibold text-light_30 mb-2 dark:text-zinc-300'>Email</span>
                                    <div className="w-full px-3">
                                        <Controller
                                            name="removeEmail"
                                            control={removeControl}
                                            render={({ field }) => (
                                                <input
                                                    id="removeEmail"
                                                    type="email"
                                                    name="removeEmail"
                                                    className="form-input w-full text-gray-300"
                                                    placeholder="Email of user to remove premium access"
                                                    {...field}
                                                />
                                            )}
                                            rules={{
                                                required: 'email is required',
                                                validate: {
                                                    isEmailValid,
                                                },
                                            }}
                                        />
                                        {isRemoveSubmitted && removeErrors.removeEmail && <p className="text-red-600 text-sm italic">{removeErrors.removeEmail.message}</p>}
                                    </div>
                                </div>   
                                <div className="flex flex-wrap -mx-3 mt-6">
                                    <div className="w-full px-3">
                                        <button type="submit" className="btn-brand-2 mt-4">Delete User</button>
                                    </div>
                                </div>
                            </form>
                        </UsersModal> 
                        <UsersModal title={isEdit ? 'Edit User Details' : 'Add User'} setModalOpen={setModalOpen} modalOpen={modalOpen}>
                            <form onSubmit={handleUserSubmit(handleOnSubmit)} className='mt-6'>
                                <div className="flex flex-wrap -mx-3 mb-4">
                                <span className='px-3 text-lg font-semibold text-light_30 mb-2 dark:text-zinc-300'>Name</span>
                                    <div className="w-full px-3">
                                        <Controller
                                            name="name"
                                            control={userControl}
                                            render={({ field }) => (
                                                <input
                                                    id="name"
                                                    type="text"
                                                    name="name"
                                                    className="form-input w-full text-gray-300"
                                                    placeholder="User's Name"
                                                    {...field}
                                                />
                                            )}
                                            rules={{
                                                required: 'name is required',
                                            }}
                                        />
                                        {isUserSubmitted && userErrors.name && <p className="text-red-600 text-sm italic">{userErrors.name.message}</p>}
                                    </div>
                                </div>
                                <div className="flex flex-wrap -mx-3 mb-4">
                                <span className='px-3 text-lg font-semibold text-light_30 mb-2 dark:text-zinc-300'>Username</span>
                                    <div className="w-full px-3">
                                        <Controller
                                            name="username"
                                            control={userControl}
                                            render={({ field }) => (
                                                <input
                                                    id="username"
                                                    type="text"
                                                    name="username"
                                                    className="form-input w-full text-gray-300"
                                                    placeholder="Username on TUF"
                                                    {...field}
                                                    disabled={isEdit}
                                                />
                                            )}
                                            rules={{
                                                required: !isEdit ? 'username is required' : false, 
                                            }}
                                        />
                                        {isUserSubmitted && userErrors.username && <p className="text-red-600 text-sm italic">{userErrors.username.message}</p>}
                                    </div>
                                </div>
                                <div className="flex flex-wrap -mx-3 mb-4">
                                <span className='px-3 text-lg font-semibold text-light_30 mb-2 dark:text-zinc-300'>Email</span>
                                    <div className="w-full px-3">
                                        <Controller
                                            name="email"
                                            control={userControl}
                                            render={({ field }) => (
                                                <input
                                                    id="email"
                                                    type="email"
                                                    name="email"
                                                    className="form-input w-full text-gray-300"
                                                    placeholder="Email"
                                                    {...field}
                                                />
                                            )}
                                            rules={{
                                                required: 'email is required',
                                                validate: {
                                                    isEmailValid,
                                                },
                                            }}
                                        />
                                        {isUserSubmitted && userErrors.email && <p className="text-red-600 text-sm italic">{userErrors.email.message}</p>}
                                    </div>
                                </div>   
                                <div className="flex flex-wrap -mx-3 mb-4">
                                <span className='px-3 text-lg font-semibold text-light_30 mb-2 dark:text-zinc-300'>Status</span>
                                    <div className="w-full px-3">
                                        <Controller
                                            name="isPremium"
                                            control={userControl}
                                            defaultValue="1"
                                            render={({ field }) => (
                                                <select
                                                    {...field}
                                                    className="form-select w-full text-gray-300"
                                                >
                                                    <option value="1">Premium</option>
                                                    <option value="0">Normal</option>
                                                </select>
                                            )}
                                            rules={{
                                                required: 'Status is required',
                                            }}
                                        />
                                        {userErrors.isPremium && <p className="text-red-600 text-sm italic">{userErrors.isPremium.message}</p>}
                                    </div>
                                </div>             
                                <div className="flex flex-wrap -mx-3 mb-4">
                                <span className='px-3 text-lg font-semibold text-light_30 mb-2 dark:text-zinc-300'>Medium</span>
                                    <div className="w-full px-3">
                                        <Controller
                                            name="medium"
                                            control={userControl}
                                            defaultValue="privileged"
                                            render={({ field }) => (
                                                <select
                                                id="medium"
                                                name="medium"
                                                className="form-select w-full text-gray-300 "
                                                {...field}
                                                >
                                                <option value="paid">Paid</option>
                                                <option value="privileged">Privileged</option>
                                                </select>
                                            )}
                                            rules={{
                                                required: 'medium is required',
                                            }}
                                        />
                                        {isUserSubmitted && userErrors.medium && <p className="text-red-600 text-sm italic">{userErrors.medium.message}</p>}
                                    </div>
                                </div>
                                {allPlans && allPlans.length > 0 ? (
                                    <>
                                    <div className="flex flex-wrap -mx-3 mb-4">
                                    <span className='px-3 text-lg font-semibold text-light_30 mb-2 dark:text-zinc-300'>Plan Name</span>
                                        <div className="w-full px-3">
                                            <Controller
                                                name="plan_name"
                                                control={userControl}
                                                render={({ field }) => (
                                                    <select
                                                    id="plan_name"
                                                    name="plan_name"
                                                    className="form-select w-full text-gray-300 "
                                                    {...field}
                                                    >
                                                    <option value="">Select Plan</option>
                                                    {allPlans.map((plan) => (
                                                        <option key={plan.id} value={plan.name}>{plan.name}</option>
                                                    ))}
                                                    </select>
                                                )}
                                                rules={{
                                                    required: 'plan name is required',
                                                }}
                                            />
                                            {isUserSubmitted && userErrors.plan_name && <p className="text-red-600 text-sm italic">{userErrors.plan_name.message}</p>}
                                        </div>
                                    </div>
                                    <div className="flex flex-wrap -mx-3 mb-4">
                                    <span className='px-3 text-lg font-semibold text-light_30 mb-2 dark:text-zinc-300'>Validity</span>
                                        <div className="w-full px-3">
                                            <Controller
                                                name="validity"
                                                control={userControl}
                                                render={({ field }) => (
                                                    <select
                                                        id="validity"
                                                        name="validity"
                                                        className="form-select w-full text-gray-300"
                                                        {...field}
                                                    >
                                                        <option value="">Select Validity</option>
                                                        <option value="6 months">6 months</option>
                                                        <option value="12 months">12 months</option>
                                                        <option value="lifetime">Lifetime</option>
                                                    </select>
                                                )}
                                                rules={{
                                                    required: 'validity is required',
                                                }}
                                            />
                                            {isUserSubmitted && userErrors.validity && <p className="text-red-600 text-sm italic">{userErrors.validity.message}</p>}
                                        </div>
                                    </div>
                                    </>
                                ) : (
                                    <div className='flex justify-center items-center text-4xl dark:text-zinc-300'>
                                        No Plan
                                    </div>
                                )}
                               
                               
                                <div className="flex flex-wrap -mx-3 mt-6">
                                    <div className="w-full px-3">
                                        <button type="submit" className="btn-brand-2 w-full">{isEdit ? 'Update User' : 'Add User'}</button>
                                    </div>
                                </div>
                            </form>
                        </UsersModal>
                    </div>
                </main>
            </div>
        </div>
    )
}

export default GrantAccess