import { combineReducers } from '@reduxjs/toolkit';

import authReducer from '../slices/authSlice';
import syllabusReducer from '../slices/syllabusSlice';
import premiumReducer from '../slices/premiumSlice';
import sessionReducer from '../slices/sessionsSlice';
import notificationReducer from '../slices/notificationSlice';
import referralReducer from '../slices/referralSlice';
import bugReducer from '../slices/bugSlice';
import grantAccessReducer from '../slices/grantAccessSlice';
import salesTrackerReducer from '../slices/salesTrackerSlice';
import configReducer from '../slices/configSlice';
import commentsReducer from '../slices/commentSlice';
import judgeStatsSlice from '../slices/judgeStatsSlice';
import userStatsSlice from '../slices/userStatsSlice';
import newSyllabusSlice from '../slices/new-syllabusSlice';
import storiesSlice from '../slices/successStoriesSlice';
import coursesSlice from '../slices/coursesSlice';
import callsSlice from '../slices/callsSlice';

const rootReducer = combineReducers({
  auth: authReducer,
  syllabus: syllabusReducer,
  premium: premiumReducer,
  sessions: sessionReducer,
  notifications: notificationReducer,
  referrals: referralReducer,
  bugs: bugReducer,
  grantAccess: grantAccessReducer,
  sales: salesTrackerReducer,
  configs: configReducer,
  comments: commentsReducer,
  judgeStats: judgeStatsSlice,
  userStats: userStatsSlice,
  new_syllabus: newSyllabusSlice,
  stories: storiesSlice,
  courses: coursesSlice,
  calls: callsSlice,
});

export default rootReducer;
