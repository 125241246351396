import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    session: {},
    allSessions: [],
    enrolledUsers: '',
};

const sessionSlice = createSlice({
    name: "sessions",
    initialState: initialState,
    reducers: {
        setSession(state, value) {
            state.session = value.payload;
        },
        setAllSessions(state, value) {
            state.allSessions = value.payload;
        },
        setEnrolledUsers(state, value) {
            state.enrolledUsers = value.payload;
        },
        clearEnrolledUsers(state) {
            state.enrolledUsers = '';
        }
    },
});

export const { setSession, setAllSessions, setEnrolledUsers, clearEnrolledUsers } = sessionSlice.actions;
export default sessionSlice.reducer;