/* eslint-disable no-unused-vars */
import Header from '../../components/Header/Header';
import Sidebar from '../../components/Sidebar/Sidebar';
import React, { useEffect, useState } from 'react';
import DateRangePicker from '../../components/DateRangePicker';
import CallsReport from './CallsReport';
import { useLocation } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { setUserType } from '../../slices/callsSlice';
import { getUserByEmail } from '../../services/operations/calls';
import CallsTable from '../../components/Table/CallsTable/CallsTable';
import { Search, RotateCcwIcon, UserRoundPlus } from 'lucide-react';
import AddWhatsappUserModal from '../../components/SalesModals/AddWhatsappUserModal';

const SalesTabs = ({ userType }) => {
  const [sidebarOpen, setSidebarOpen] = useState(false);
  const [category, setCategory] = useState('all');
  const [isEnrolled, setIsEnrolled] = useState('all');
  const [emailInput, setEmailInput] = useState('');
  const [email, setEmail] = useState('');
  const userViaEmail = useSelector((state) => state.calls.userViaEmail);
  const [isEmailEditable, setIsEmailEditable] = useState(false);
  const [showUserViaEmail, setShowUserViaEmail] = useState(false);
  const [addUserModalOpen, setAddUserModalOpen] = useState(false);
  const dispatch = useDispatch();
  const location = useLocation();
  const { pathname } = location;

  const toggleEmailEdit = () => {
    setIsEmailEditable(true);
    setEmailInput(email);
  };

  const handleEmailSave = () => {
    setEmail(emailInput.trim());
    setIsEmailEditable(false);
  };

  const handleSearch = () => {
    if (email) {
      dispatch(getUserByEmail(email, userType));
      setEmail('');
      setEmailInput('');
      setIsEmailEditable(false);
    }
  };

  const [startDate, setStartDate] = useState(() => {
    const now = new Date();
    return new Date(now.getFullYear(), now.getMonth(), 1);
  });
  const [endDate, setEndDate] = useState(new Date());
  const count = useSelector((state) => state.calls.allUserCalls.count);
  const filtered = useSelector((state) => state.calls.filtered);

  useEffect(() => {
    dispatch(setUserType, userType);
  }, [userType, dispatch]);

  useEffect(() => {
    const hasContent = userViaEmail && Object.keys(userViaEmail).length > 0;
    setShowUserViaEmail(hasContent);
  }, [userViaEmail]);

  return (
    <div className='flex dark:bg-dark font-primary h-screen overflow-hidden bg-[#fafafa]'>
      <Sidebar
        type={pathname && pathname?.split('/')[1]}
        sidebarOpen={sidebarOpen}
        setSidebarOpen={setSidebarOpen}
      />
      <div className='relative flex flex-col flex-1 overflow-y-auto no-scrollbar overflow-x-hidden'>
        <Header sidebarOpen={sidebarOpen} setSidebarOpen={setSidebarOpen} />
        <div className='px-4 sm:px-6 lg:px-8 py-8 w-full max-w-9xl mx-auto'>
          {/* Status Tabs */}
          <div className='mb-6 flex gap-x-4 border-b justify-between'>
            <div>
              {['all', 'enrolled', 'not enrolled'].map((tab) => (
                <button
                  key={tab}
                  onClick={() => setIsEnrolled(tab)}
                  className={`px-4 py-2 ${
                    isEnrolled === tab ? 'border-b-2 border-brand text-brand' : 'text-gray-600'
                  }`}
                >
                  {tab.toUpperCase()}
                </button>
              ))}
            </div>

            <div className='flex flex-col items-start justify-center py-4'>
              <div className='flex items-center space-x-4'>
                <span className='font-semibold'>Count:</span>
                <span className='block w-50 h-10 text-white-950 border border-gray-200 rounded-md py-2 px-3 cursor-pointer focus:outline-none bg-#fff dark:border-zinc-700 text-base'>
                  {count - filtered}
                </span>
              </div>
            </div>

            <div className='flex flex-col items-start justify-center py-4'>
              <div className='flex items-center space-x-4'>
                <span className='font-semibold'>Email:</span>
                {isEmailEditable ? (
                  <input
                    type='email'
                    value={emailInput}
                    onChange={(e) => setEmailInput(e.target.value)}
                    onBlur={handleEmailSave}
                    className='w-64 h-10 px-3 py-2 border rounded-md text-sm sm:text-base focus:ring focus:ring-red-500 focus:outline-none bg-#fff dark:border-zinc-700'
                    placeholder='Enter email'
                    autoFocus
                  />
                ) : (
                  <span
                    className='block w-64 h-10 text-gray-700 border border-gray-200 rounded-md py-2 px-3 cursor-pointer focus:outline-none bg-#fff dark:border-zinc-700'
                    onClick={toggleEmailEdit}
                  >
                    {email || ''}
                  </span>
                )}
                <button
                  className='px-4 py-2 rounded text-sm font-medium bg-red-500 text-white hover:bg-red-600'
                  onClick={handleSearch}
                >
                  <Search />
                </button>
                {showUserViaEmail && (
                  <button
                    className='px-4 py-2 rounded text-sm font-medium bg-red-500 text-white hover:bg-red-600'
                    onClick={() => {
                      setShowUserViaEmail(false);
                    }}
                  >
                    <RotateCcwIcon />
                  </button>
                )}
              </div>
            </div>
          </div>

          <div className='flex items-start gap-x-4 justify-between'>
            <div className='flex items-start gap-x-4 justify-between'>
              {/* Category filter */}
              <div className='flex items-center gap-x-4'>
                <label
                  htmlFor='category'
                  className='text-md font-medium text-gray-800 dark:text-zinc-200'
                >
                  Category:
                </label>
                <select
                  id='category'
                  value={category || ''}
                  onChange={(e) => setCategory(e.target.value)}
                  className='form-select'
                >
                  {userType !== 'whatsapp' ? (
                    <>
                      <option value='all'>All</option>
                      <option value='not called'>Not Called</option>
                      <option value='wrong num'>Wrong Number</option>
                      <option value='unreachable'>Unreachable</option>
                      <option value='bought on call'>Bought on Call</option>
                      <option value='call later'>Call Later</option>
                      <option value='busy'>Busy</option>
                    </>
                  ) : (
                    <>
                      <option value='all'>All</option>
                      <option value='not interested'>Not Interested</option>
                      <option value='will buy later'>Will Buy Later</option>
                      <option value='bought'>Bought</option>
                    </>
                  )}
                </select>
              </div>

              {userType === 'whatsapp' && (
                <div className='mb-4 flex items-center gap-x-4 justify-end'>
                  <button
                    className='px-4 py-2 rounded text-sm font-medium bg-red-500 text-white hover:bg-red-600'
                    onClick={() => setAddUserModalOpen(true)}
                  >
                    <span className='flex items-center gap-2'>
                      <UserRoundPlus />
                      Add User
                    </span>
                  </button>
                </div>
              )}  
            </div>

            <DateRangePicker
              className='items-center'
              startDate={startDate}
              endDate={endDate}
              setStartDate={setStartDate}
              setEndDate={setEndDate}
            />
          </div>
          {showUserViaEmail ? (
            <CallsTable calls={userViaEmail.userDetails} isEnrolled={userViaEmail?.isEnrolled} />
          ) : (
            <CallsReport
              userType={userType}
              isEnrolled={isEnrolled}
              category={category}
              startDate={startDate}
              endDate={endDate}
            />
          )}
        </div>
      </div>
      <AddWhatsappUserModal modalOpen={addUserModalOpen} setModalOpen={setAddUserModalOpen} />
    </div>
  );
};

export default SalesTabs;
