/* eslint-disable no-unused-vars */
import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
import Transition from '../../utils/Transition';
import 'react-quill/dist/quill.snow.css';
import { useDispatch } from 'react-redux';
import { addWhatsappUser } from '../../services/operations/calls';
import { setUserAdded } from '../../slices/callsSlice';

function AddWhatsappUserModal({ modalOpen, setModalOpen }) {
  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm();

  const [callStatus, setCallStatus] = useState('not interested');
  const [codeUsed, setCodeUsed] = useState('NONE');
  const [isEditingCodeUsed, setIsEditingCodeUsed] = useState(false);
  const dispatch = useDispatch();

  const onSubmit = (data) => {
    dispatch(addWhatsappUser(data?.email, data?.name, data?.phone, data?.status, data?.code));
    dispatch(setUserAdded(true));
    setModalOpen(false);
  };

  const handleCodeUsedChange = (value) => {
    setCodeUsed(value);
    setValue('code', value);
  };

  return (
    <>
      <Transition
        className='fixed inset-0 bg-gray-900 bg-opacity-30 z-50 transition-opacity'
        show={modalOpen}
        enter='transition ease-out duration-200'
        enterStart='opacity-0'
        enterEnd='opacity-100'
        leave='transition ease-out duration-100'
        leaveStart='opacity-100'
        leaveEnd='opacity-0'
        aria-hidden='true'
      />
      <Transition
        className='fixed inset-0 z-50 flex items-center justify-center px-4 sm:px-6'
        show={modalOpen}
        enter='transition ease-in-out duration-200'
        enterStart='opacity-0 translate-y-4'
        enterEnd='opacity-100 translate-y-0'
        leave='transition ease-in-out duration-200'
        leaveStart='opacity-100 translate-y-0'
        leaveEnd='opacity-0 translate-y-4'
      >
        <div className='bg-white dark:bg-dark_50 rounded-lg shadow-lg overflow-y-auto no-scrollbar w-[90%] sm:w-[50%] md:w-[40%] lg:w-[30%] max-w-[95%] max-h-[80%] flex flex-col p-4 sm:p-6'>
          <h2 className='text-xl text-center font-semibold mb-4'>Entry Details</h2>
          <form onSubmit={handleSubmit(onSubmit)}>
            <div className='mb-4 flex flex-col sm:flex-row justify-between gap-2 sm:gap-6 items-center'>
              <label className='font-semibold mb-1 block text-sm sm:text-base'>Email</label>
              <input
                type='email'
                className='w-full h-10 px-3 py-2 border rounded-md text-sm sm:text-base focus:ring focus:ring-red-500 focus:outline-none bg-#fff dark:border-zinc-700'
                {...register('email', { required: 'Email is required' })}
              />
              {errors.email && <p className='text-red-500 text-sm'>{errors.email.message}</p>}
            </div>

            <div className='mb-4 flex flex-col sm:flex-row justify-between gap-2 sm:gap-6 items-center'>
              <label className='font-semibold mb-1 block text-sm sm:text-base'>Name</label>
              <input
                type='text'
                className='w-full h-10 px-3 py-2 border rounded-md text-sm sm:text-base focus:ring focus:ring-red-500 focus:outline-none bg-#fff dark:border-zinc-700'
                {...register('name', { required: 'Name is required' })}
              />
              {errors.name && <p className='text-red-500 text-sm'>{errors.name.message}</p>}
            </div>

            <div className='mb-4 flex flex-col sm:flex-row justify-between gap-2 sm:gap-6 items-center'>
              <label className='font-semibold mb-1 block text-sm sm:text-base'>Phone</label>
              <input
                type='tel'
                className='w-full h-10 px-3 py-2 border rounded-md text-sm sm:text-base focus:ring focus:ring-red-500 focus:outline-none bg-#fff dark:border-zinc-700'
                {...register('phone', { required: 'Phone is required' })}
              />
              {errors.phone && <p className='text-red-500 text-sm'>{errors.phone.message}</p>}
            </div>

            <div className='mb-4 mt-2 flex flex-col sm:flex-row text-xs sm:text-sm items-start sm:items-center justify-start gap-2 sm:gap-6'>
              <label className='font-semibold mb-1 block text-sm sm:text-base whitespace-nowrap'>
                Buying Status:
              </label>
              <select
                className='max-w-[60%] sm:min-w-[30%] h-10 px-3 py-2 text-xs sm:text-sm bg-gray-50 dark:bg-zinc-800 rounded border border-gray-200 dark:border-zinc-700 focus:outline-none focus:ring focus:ring-red-500 appearance-none'
                {...register('status', {
                  onChange: (e) => {
                    setCallStatus(e.target.value);
                  },
                })}
                value={callStatus}
              >
                <option value='not interested'>Not Interested</option>
                <option value='will buy later'>Will Buy Later</option>
                <option value='bought'>Bought</option>
              </select>
            </div>

            <div className='mb-4'>
              <div className='flex flex-col sm:flex-row items-start sm:items-center justify-start gap-4 sm:gap-10'>
                <label className='font-semibold mb-1 block text-sm sm:text-base whitespace-nowrap'>
                  Code Used:
                </label>
                {isEditingCodeUsed ? (
                  <input
                    type='text'
                    value={codeUsed}
                    onChange={(e) => setCodeUsed(e.target.value)}
                    onKeyDown={(e) => {
                      if (e.key === 'Enter') {
                        handleCodeUsedChange(codeUsed.toUpperCase());
                        setCodeUsed((prev) => prev.toUpperCase());
                        setIsEditingCodeUsed(false);
                      }
                    }}
                    onBlur={() => {
                      handleCodeUsedChange(codeUsed.toUpperCase());
                      setCodeUsed((prev) => prev.toUpperCase());
                      setIsEditingCodeUsed(false);
                    }}
                    className='max-w-[60%] sm:min-w-[30%] px-2 py-1 border rounded-md focus:ring focus:ring-red-500 focus:outline-none text-sm'
                    autoFocus
                  />
                ) : (
                  <span
                    className='max-w-[60%] sm:min-w-[30%] px-2 py-1 border rounded-md cursor-pointer focus:ring focus:ring-red-500 focus:outline-none text-sm'
                    onClick={() => setIsEditingCodeUsed(true)}
                  >
                    {codeUsed || 'None'}
                  </span>
                )}
                <input type='hidden' {...register('code')} value={codeUsed} />
              </div>
            </div>

            <div className='flex flex-col sm:flex-row justify-end gap-2 sm:gap-4'>
              <button
                type='button'
                className='px-2 py-1 border text-sm border-black dark:border-white bg-zinc-50 dark:bg-zinc-800 rounded-md hover:text-red-600 hover:border-red-600 dark:hover:text-red-500 dark:hover:border-red-500 text-black dark:text-white'
                onClick={() => setModalOpen(false)}
              >
                Cancel
              </button>
              <button
                type='submit'
                className='px-2 py-1 text-sm bg-red-500 text-white rounded hover:bg-red-800'
              >
                Submit
              </button>
            </div>
          </form>
        </div>
      </Transition>
    </>
  );
}

export default AddWhatsappUserModal;
