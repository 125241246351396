import React, { useEffect, useState } from 'react';
import { Controller, useFieldArray, useForm } from 'react-hook-form';
import { Tag, X, Plus, Trash2 } from 'lucide-react';
import Accordion from '../../../components/Accordion/Accordion';
import { useSelector, useDispatch } from 'react-redux';
import { updateDSAProblem } from '../../../services/operations/new-syllabus';

const MiscEdit = () => {
  const { CurrentProblem } = useSelector((state) => state.new_syllabus);
  const dispatch = useDispatch();

  const { control, reset, handleSubmit } = useForm({
    defaultValues: {
      difficulty: CurrentProblem?.misc?.difficulty || 'Easy',
      tags: CurrentProblem?.misc?.tags || [],
      language: CurrentProblem?.misc?.language || [],
      facts: CurrentProblem?.misc?.facts || '',
      hints: CurrentProblem?.misc?.hints || [],
      frequently_occuring_doubts: CurrentProblem?.misc?.frequently_occuring_doubts || [],
      interview_followup_questions: CurrentProblem?.misc?.interview_followup_questions || [],
    },
  });

  useEffect(() => {
    if (CurrentProblem?.misc) {
      reset({
        difficulty: CurrentProblem?.misc?.difficulty || 'Easy',
        tags: CurrentProblem?.misc?.tags || [],
        language: CurrentProblem?.misc?.language || [],
        facts: CurrentProblem?.misc?.facts || '',
        hints: CurrentProblem?.misc?.hints || [],
        frequently_occuring_doubts: CurrentProblem?.misc?.frequently_occuring_doubts || [],
        interview_followup_questions: CurrentProblem?.misc?.interview_followup_questions || [],
      });
    }
  }, [CurrentProblem, reset]);

  // Add hints field array
  const {
    fields: hintFields,
    append: appendHint,
    remove: removeHint,
  } = useFieldArray({
    control,
    name: 'hints',
  });

  // Add doubtFields array
  const {
    fields: doubtFields,
    append: appendDoubt,
    remove: removeDoubt,
  } = useFieldArray({
    control,
    name: 'frequently_occuring_doubts',
  });

  // Add followupFields array
  const {
    fields: followupFields,
    append: appendFollowup,
    remove: removeFollowup,
  } = useFieldArray({
    control,
    name: 'interview_followup_questions',
  });

  const { isProd } = useSelector((state) => state.auth);
  
  // on submit form
  const onSubmit = (data) => {
    const miscData = {
      id: CurrentProblem?.id,
      type: 'misc',
      misc: data,
      slug: CurrentProblem?.slug,
    };
    dispatch(updateDSAProblem(miscData, isProd));
  };

  return (
    <div className='flex flex-col gap-y-8'>
      <form onSubmit={handleSubmit(onSubmit)} className='space-y-8'>
        {/* Difficulty Tag */}
        <div>
          <label className='text-gray-800 dark:text-zinc-300 text-[16px] font-medium'>
            Difficulty Tag <span className='text-red-500'>*</span>
          </label>
          <Controller
            name='difficulty'
            control={control}
            render={({ field }) => (
              <select
                {...field}
                className='mt-1 w-full px-4 py-2 dark:bg-zinc-800 border border-gray-600 rounded-md text-gray-300'
              >
                {['Easy', 'Medium', 'Hard'].map((option) => (
                  <option key={option} value={option}>
                    {option}
                  </option>
                ))}
              </select>
            )}
          />
        </div>

        {/* Company Tags */}
        <div>
          <label className='text-gray-800 dark:text-zinc-300 text-[16px] font-medium'>
            Company Tags <span className='text-red-500'>*</span>
          </label>
          <CompanyTagsSelector control={control} name='tags' options={companyOptions} />
        </div>

        <div>
          <label className='text-gray-800 dark:text-zinc-300 text-[16px] font-medium'>
            Supported Languages <span className='text-red-500'>*</span>
          </label>
          <LanguageSelector control={control} name='language' options={languageOptions} />
        </div>

        {/* Facts */}
        <div>
          <label className='text-gray-800 dark:text-zinc-300 text-[16px] font-medium'>
            Facts <span className='text-red-500'>*</span>
          </label>
          <Controller
            name='facts'
            control={control}
            render={({ field }) => (
              <input
                {...field}
                className='mt-1 w-full px-4 py-2 dark:bg-zinc-800 border border-gray-600 rounded-md text-gray-300'
                placeholder='Enter facts...'
              />
            )}
          />
        </div>

        {/* Hints Section */}
        <Accordion title='Hints'>
          <HintsSection
            hintFields={hintFields}
            appendHint={appendHint}
            removeHint={removeHint}
            control={control}
          />
        </Accordion>

        {/* Frequently Occurring Doubts */}
        <Accordion title='Frequently Occurring Doubts'>
          <QASection
            fields={doubtFields}
            append={appendDoubt}
            remove={removeDoubt}
            name='frequently_occuring_doubts'
            title='Frequently Occurring Doubts'
            control={control}
          />
        </Accordion>

        {/* Interviewer Followup Questions */}
        <Accordion title='Interviewer Followup Questions'>
          <QASection
            fields={followupFields}
            append={appendFollowup}
            remove={removeFollowup}
            name='interview_followup_questions'
            title='Interviewer Followup Questions'
            control={control}
          />
        </Accordion>

        {/* Submit Button */}
        <button
          type='submit'
          className='w-full bg-blue-500 text-white py-2 px-4 rounded-md hover:bg-blue-600 transition-colors'
        >
          Save Changes
        </button>
      </form>
    </div>
  );
};

// Hints Component 
const HintsSection = ({ hintFields, control, removeHint, appendHint }) => (
  <div className='space-y-4'>
    <div className='space-y-4'>
      {hintFields.map((field, index) => (
        <div key={field.id} className='flex flex-col gap-2'>
          <label htmlFor={`hints.${index}.hint`}> Hint {index + 1} </label>
          <div className='flex gap-2'>
            <Controller
              name={`hints.${index}.hint`}
              control={control}
              render={({ field }) => (
                <input
                  {...field}
                  className='flex-1 px-4 py-2 dark:bg-zinc-800  border border-gray-600 rounded-md text-gray-200 focus:ring-2 focus:ring-blue-500 focus:border-transparent'
                  placeholder='Enter hint...'
                />
              )}
            />
            {hintFields.length > 0 && (
              <button
                type='button'
                onClick={() => removeHint(index)}
                className='p-2 text-red-500 hover:text-red-600 dark:bg-zinc-800 rounded-md border border-gray-600'
              >
                <Trash2 size={18} />
              </button>
            )}
          </div>
        </div>
      ))}

      <button
        type='button'
        onClick={() => appendHint({ hint: '' })}
        className='w-full py-2 border-2 border-dashed border-gray-600 rounded-lg hover:border-blue-500 hover:text-blue-500 transition-colors flex items-center justify-center gap-2 text-gray-400'
      >
        <Plus size={18} />
        <span>Add New Hint</span>
      </button>
    </div>
  </div>
);

// QA Component : It will generate FADs & IFQs
const QASection = ({ fields, append, remove, name, control }) => (
  <div className='space-y-4'>
    <div className='space-y-6'>
      {fields.map((field, index) => (
        <div key={field.id} className='p-4 border border-gray-600 rounded-lg dark:bg-zinc-800/50'>
          <div className='flex justify-between items-center mb-4'>
            <span className='text-gray-300 font-medium'>Q&A Pair {index + 1}</span>
            {fields.length > 0 && (
              <button
                type='button'
                onClick={() => remove(index)}
                className='text-red-500 hover:text-red-600'
              >
                <Trash2 size={18} />
              </button>
            )}
          </div>

          <div className='space-y-4'>
            <div>
              <label className='block text-sm text-gray-400 mb-1'>Question</label>
              <Controller
                name={`${name}.${index}.question`}
                control={control}
                render={({ field }) => (
                  <input
                    {...field}
                    className='w-full px-4 py-2 dark:bg-zinc-800  border border-gray-600 rounded-md text-gray-200 focus:ring-2 focus:ring-blue-500 focus:border-transparent'
                    placeholder='Enter question...'
                  />
                )}
              />
            </div>

            <div>
              <label className='block text-sm text-gray-400 mb-1'>Answer</label>
              <Controller
                name={`${name}.${index}.answer`}
                control={control}
                render={({ field }) => (
                  <textarea
                    {...field}
                    className='w-full px-4 py-2 dark:bg-zinc-800  border border-gray-600 rounded-md text-gray-200 focus:ring-2 focus:ring-blue-500 focus:border-transparent'
                    placeholder='Enter answer...'
                    rows={3}
                  />
                )}
              />
            </div>
          </div>
        </div>
      ))}

      <button
        type='button'
        onClick={() => append({ question: '', answer: '' })}
        className='w-full py-2 border-2 border-dashed border-gray-600 rounded-lg hover:border-blue-500 hover:text-blue-500 transition-colors flex items-center justify-center gap-2 text-gray-400'
      >
        <Plus size={18} />
        <span>Add New Q&A Pair</span>
      </button>
    </div>
  </div>
);

// CompanyTagsSelector component
const CompanyTagsSelector = ({ control, name, options }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [searchTerm, setSearchTerm] = useState('');

  return (
    <Controller
      name={name}
      control={control}
      render={({ field }) => (
        <div className='relative mt-1'>
          {field.value.length > 0 && (
            <div className='mt-2 flex flex-wrap gap-2'>
              {field.value.map((tag) => (
                <span
                  key={tag}
                  className='inline-flex items-center px-2 py-1 rounded-full text-sm font-medium bg-blue-100 text-blue-800 dark:bg-blue-900 dark:text-blue-200'
                >
                  {tag}
                  <button
                    type='button'
                    onClick={() => field.onChange(field.value.filter((t) => t !== tag))}
                    className='ml-1 inline-flex items-center p-0.5 rounded-full hover:bg-blue-200 dark:hover:bg-blue-800 focus:outline-none'
                  >
                    <X size={14} />
                  </button>
                </span>
              ))}
            </div>
          )}

          <button
            type='button'
            onClick={() => setIsOpen(!isOpen)}
            className='mt-2 w-full px-4 py-2 text-left border border-gray-600 rounded-md shadow-sm focus:outline-none focus:ring-2 focus:ring-blue-500 dark:bg-zinc-800'
          >
            <div className='flex items-center justify-between text-gray-300'>
              <span className='flex items-center gap-2'>
                <Tag size={16} />
                {field.value.length > 0
                  ? `${field.value.length} tags selected`
                  : 'Select company tags'}
              </span>
              <svg
                className={`w-5 h-5 transition-transform ${isOpen ? 'rotate-180' : ''}`}
                xmlns='http://www.w3.org/2000/svg'
                viewBox='0 0 20 20'
                fill='currentColor'
              >
                <path
                  fillRule='evenodd'
                  d='M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z'
                  clipRule='evenodd'
                />
              </svg>
            </div>
          </button>

          {isOpen && (
            <div className='absolute z-10 w-full mt-1 dark:bg-zinc-800 border border-gray-600 rounded-md shadow-lg'>
              <div className='p-2'>
                <input
                  type='text'
                  placeholder='Search tags...'
                  value={searchTerm}
                  onChange={(e) => setSearchTerm(e.target.value)}
                  className='w-full px-3 py-2 border border-gray-600 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500 dark:bg-zinc-800  text-gray-300'
                />
              </div>
              <div className='max-h-60 overflow-y-auto'>
                {options
                  .filter((tag) => tag.toLowerCase().includes(searchTerm.toLowerCase()))
                  .map((tag) => (
                    <label
                      key={tag}
                      className='flex items-center px-4 py-2 hover:bg-gray-700 cursor-pointer'
                    >
                      <input
                        type='checkbox'
                        checked={field.value.includes(tag)}
                        onChange={() => {
                          const newValue = field.value.includes(tag)
                            ? field.value.filter((t) => t !== tag)
                            : [...field.value, tag];
                          field.onChange(newValue);
                        }}
                        className='mr-2'
                      />
                      <span className='text-gray-300'>{tag}</span>
                    </label>
                  ))}
              </div>
            </div>
          )}
        </div>
      )}
    />
  );
};

const companyOptions = [
  'Apple',
  'Microsoft',
  'Google',
  'Amazon',
  'Meta',
  'NVIDIA',
  'Samsung',
  'TSMC',
  'Intel',
  'Oracle',
  'IBM',
  'Dell Technologies',
  'HP Inc.',
  'Cisco',
  'SAP',
  'Adobe',
  'PayPal',
  'Salesforce',
  'TikTok',
  'Uber',
  'Twitter',
  'Zoom',
  'Square',
  'Shopify',
  'Spotify',
  'Alibaba',
  'Tencent',
  'Netflix',
  'Baidu',
  'Sony',
  'Lyft',
  'Atlassian',
  'Palantir',
  'ServiceNow',
  'Infosys',
  'Capgemini',
  'Fujitsu',
  'Accenture',
  'Pinterest',
  'CrowdStrike',
  'AMD',
  'SpaceX',
  'Stripe',
  'Snowflake',
  'GitLab',
  'MongoDB',
  'Qualcomm',
  'Reddit',
  'Slack',
];


const LanguageSelector = ({ control, name, options }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [searchTerm, setSearchTerm] = useState('');

  return (
      <Controller
          name={name}
          control={control}
          render={({ field }) => (
              <div className='relative'>
                  {field.value.length > 0 && (
                      <div className='mt-2 flex flex-wrap gap-4'>
                          {field.value.map((language) => (
                              <span
                                  key={language}
                                  className='inline-flex items-center px-2 py-1 rounded-full text-sm font-medium bg-blue-100 text-blue-800 dark:bg-blue-900 dark:text-blue-200 mb-5'
                              >
                                  {language}
                                  <button
                                      type='button'
                                      onClick={() =>
                                          field.onChange(field.value.filter((t) => t !== language))
                                      }
                                      className='ml-1 inline-flex items-center p-0.5 rounded-full text-blue-400 hover:bg-blue-200 dark:hover:bg-blue-800 focus:outline-none'
                                  >
                                      <X size={14} />
                                  </button>
                              </span>
                          ))}
                      </div>
                  )}
                  <button
                      type='button'
                      onClick={() => setIsOpen(!isOpen)}
                      className='w-full px-4 py-2 text-left  border border-gray-300 dark:border-gray-600 rounded-md shadow-sm focus:outline-none focus:ring-2 focus:ring-blue-500'
                  >
                      <div className='flex items-center justify-between'>
                          <span className='flex items-center gap-2 text-gray-700 dark:text-gray-300'>
                              <Tag size={16} />
                              {field.value.length > 0
                                  ? `${field.value.length} languages selected`
                                  : 'Select supported languages'}
                          </span>
                          <svg
                              className={`w-5 h-5 transition-transform ${isOpen ? 'transform rotate-180' : ''}`}
                              xmlns='http://www.w3.org/2000/svg'
                              viewBox='0 0 20 20'
                              fill='currentColor'
                          >
                              <path
                                  fillRule='evenodd'
                                  d='M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z'
                                  clipRule='evenodd'
                              />
                          </svg>
                      </div>
                  </button>

                  {isOpen && (
                      <div className='absolute z-10 w-full mt-1 bg-zinc-800 border border-gray-300 dark:border-gray-600 rounded-md shadow-lg'>
                          <div className='p-2'>
                              <input
                                  type='text'
                                  placeholder='Search languages...'
                                  value={searchTerm}
                                  onChange={(e) => setSearchTerm(e.target.value)}
                                  className='w-full px-3 py-2 border border-zinc-600 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500 bg-zinc-700'
                              />
                          </div>
                          <div className='max-h-60 overflow-y-auto'>
                              {options
                                  .filter((language) =>
                                      language.toLowerCase().includes(searchTerm.toLowerCase()),
                                  )
                                  .map((language) => (
                                      <label
                                          key={language}
                                          className='flex items-center px-4 py-2 hover:bg-gray-100 dark:hover:bg-gray-700 cursor-pointer'
                                      >
                                          <input
                                              type='checkbox'
                                              checked={field.value.includes(language)}
                                              onChange={() => {
                                                  const newValue = field.value.includes(language)
                                                      ? field.value.filter((t) => t !== language)
                                                      : [...field.value, language];
                                                  field.onChange(newValue);
                                              }}
                                              className='mr-2  dark:text-blue-500'
                                          />
                                          <span className='text-gray-700 dark:text-gray-300'>
                                              {language}
                                          </span>
                                      </label>
                                  ))}
                          </div>
                      </div>
                  )}
              </div>
          )}
      />
  );
};

const languageOptions = [
  'cpp',
  'java',
  'python',
  'javascript',
];

export default MiscEdit;
