/* eslint-disable no-unused-vars */
import React, { useState } from 'react';
import Sidebar from '../../../components/Sidebar/Sidebar'
import Header from '../../../components/Header/Header'
import Stories from './AllStories';
import SuccessStoryModal from '../../../components/Shared/Modals/SuccessStoryModal';

const StoriesTab = () => {
    const [sidebarOpen, setSidebarOpen] = useState(false);
    const [currentPage, setCurrentPage] = useState(1);
    const [openTab, setOpenTab] = useState(1);
    const [storyModalOpen, setStoryModalOpen] = useState(false)

    function formatDateWithTime(date) {
        const pad = (num) => num.toString().padStart(2, '0');
        return `${date.getFullYear()}-${pad(date.getMonth() + 1)}-${pad(date.getDate())} ${pad(date.getHours())}:${pad(date.getMinutes())}:${pad(date.getSeconds())}`;
    }

    // Helper function to format dates for frontend display only (YYYY-MM-DD)
    function formatDateForInput(date) {
        const pad = (num) => num.toString().padStart(2, '0');
        return `${date.getFullYear()}-${pad(date.getMonth() + 1)}-${pad(date.getDate())}`;
    }

    // State for backend-compatible date format with time
    const [startDateBackend, setStartDateBackend] = useState(() => {
        const now = new Date();
        const startOfMonth = new Date(now.getFullYear(), now.getMonth(), 1);
        return formatDateWithTime(startOfMonth);
    });

    const [endDateBackend, setEndDateBackend] = useState(() => formatDateWithTime(new Date()));

    // State for frontend-only date input display (YYYY-MM-DD)
    const [startDateInput, setStartDateInput] = useState(() => formatDateForInput(new Date(startDateBackend)));
    const [endDateInput, setEndDateInput] = useState(() => formatDateForInput(new Date(endDateBackend)));

    // Handle input change
    const handleStartDateChange = (e) => {
        const date = e.target.value;
        setStartDateInput(date);
        setStartDateBackend(`${date} 00:00:00`);  // Default time to midnight for backend
    };

    const handleEndDateChange = (e) => {
        const date = e.target.value;
        setEndDateInput(date);
        setEndDateBackend(`${date} 23:59:59`);  // Default time to end of the day for backend
    };

    const handleTabChange = (tab) => {
        setOpenTab(tab);
        setCurrentPage(1); // Reset page on tab change if needed
    };

    const handleSuccessStory = (e) => {
        e.stopPropagation();
        setStoryModalOpen(true);
    }

    return (
        <div className="flex dark:bg-dark font-primary h-screen overflow-hidden bg-[#fafafa]">
            <Sidebar sidebarOpen={sidebarOpen} setSidebarOpen={setSidebarOpen} />
            <div className="relative flex flex-col flex-1 overflow-y-auto overflow-x-hidden">
                <Header sidebarOpen={sidebarOpen} setSidebarOpen={setSidebarOpen} />
                <div className="px-4 sm:px-6 lg:px-8 py-8 w-full max-w-9xl mx-auto">  
                    <div className="flex items-center justify-between mb-4"> 
                        <div className="flex items-center space-x-4 mb-4">
                            <input
                                type="date"
                                className="border border-gray-300 p-2 rounded-lg shadow-sm focus:outline-none focus:ring focus:border-blue-300"
                                value={startDateInput}
                                onChange={handleStartDateChange}
                            />
                            <input
                                type="date"
                                className="border border-gray-300 p-2 rounded-lg shadow-sm focus:outline-none focus:ring focus:border-blue-300"
                                value={endDateInput}
                                onChange={handleEndDateChange}
                            />
                        </div>
                        <div className='flex mt-4 mb-8 flex-row justify-between'>
                            <button onClick={(e) => handleSuccessStory(e)} className='btn-brand-2'>Add Story</button>
                        </div>
                    </div>
                    <div className="flex mb-0 list-none flex-wrap pt-3 pb-4 flex-row" role="tablist">
                        <TabButton tab={1} label="Pending" openTab={openTab} onClick={handleTabChange} />
                        <TabButton tab={2} label="Approved" openTab={openTab} onClick={handleTabChange} />
                        <TabButton tab={3} label="Disapproved" openTab={openTab} onClick={handleTabChange} />
                    </div>
                    <div className="relative flex flex-col min-w-0 break-words w-full mb-2">
                        <div className="dark:bg-dark_50 bg-white p-4 border rounded-lg border-light_10 dark:border-dark_40 flex-auto">
                            <div className="tab-content tab-space max-h-[calc(100vh-250px)] overflow-y-auto">
                                {openTab === 1 && <Stories category="pending" startDate={startDateBackend} endDate={endDateBackend} currentPage={currentPage} />}
                                {openTab === 2 && <Stories category="approved" startDate={startDateBackend} endDate={endDateBackend} currentPage={currentPage} />}
                                {openTab === 3 && <Stories category="disapproved" startDate={startDateBackend} endDate={endDateBackend} currentPage={currentPage} />}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <SuccessStoryModal modalOpen={storyModalOpen} setModalOpen={setStoryModalOpen}/>
        </div>
    );
};

const TabButton = ({ tab, label, openTab, onClick }) => (
    <div className="-mb-px flex-auto text-center">
        <button
            className={`text-sm font-bold uppercase px-3 py-3 flex gap-x-2 items-center leading-normal 
            ${openTab === tab
                ? "border-b-2 border-brand text-brand transition-all ease-in-out duration-300"
                : "border-b-2 dark:border-zinc-800 border-zinc-200 text-light_30 dark:text-light_40"
            }`}
            onClick={() => onClick(tab)}
            role="tablist"
        >
            <span>{label}</span>
        </button>
    </div>
);

export default StoriesTab;